import { useState, useEffect } from "react";

const useWindowResize = () => {
	const [width, setWidth] = useState<number>(
		window.innerWidth
	);
	const [height, setHeight] = useState<number>(
		window.innerHeight
	);

	const mobileSize: any = width < 480;

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [width, height,mobileSize]);

	return {
		mobileSize,
		width,
		height,
    
	};
};

export default useWindowResize;
