import ContactUs from '../../pages/ContactUs';
import { ReactComponent as IconContactUs } from '../assets/icon-contactus.svg';
import './EmbeddedContactUs.scss'

const EmbeddedContactUs = () => {

  return (<div className='hp-embeddedcontactus-content'>
    <div className='hp-embeddedcontactus-content-title'>
      <IconContactUs />Nous contacter
    </div>
    <ContactUs />

  </div>);
};

export default EmbeddedContactUs;
