import './Cookies.scss';
import { ReactComponent as CookieImage } from './assets/cookie-img.svg';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { routeToPlatformHomePage } from './internalrouter/HooPowAppMenu';

function Cookies() {
  const [cookies, setCookie] = useCookies(['cookiesAccepted']);
  const [showCookies, setShowCookies] = useState<boolean>();

  useEffect(() => {
    if (!cookies.cookiesAccepted) {
      console.log("cookies.cookiesAccepted", cookies.cookiesAccepted)
      setTimeout(() => setShowCookies(!cookies.cookiesAccepted), 2000);
    }
  }, [cookies])

  const cookiesAccepted = () => {
    setShowCookies(false);
    setCookie('cookiesAccepted', new Date().getTime(), { domain: process.env.REACT_APP_DOMAIN_NAME, path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 10)) });
  }

  return (<>
    <div className={`hp-cookies ${showCookies && "show"}`}>
      <div className={`hp-cookies__title`}>
        On vous offre des cookies<br />pendant votre visite :)
      </div>
      <div className={`hp-cookies__details`}>
        <CookieImage />
        <div>
          <p>
            Afin de faire fonctionner au mieux ce site, nous utilisons des cookies. Ceci nous permet de connaître (un peu) qui vous êtes et d’améliorer (beaucoup) notre service.
            <br /><br />Ces informations ne seront ni revendues, ni exploitées par un tiers.
            <br /><br />Merci de votre compréhension.
          </p>
          <button className='mainBtn' onClick={cookiesAccepted}>Je comprends<br />et j’accepte</button>
          <a href={routeToPlatformHomePage() + "/cguconnected"}>Accéder au paramètres de confidentialité</a>
        </div>
      </div>
    </div>
  </>);
}

export default Cookies;
