import { useEffect } from 'react';
import './css/MainLayout.scss';

function MainLayout(props: { children: any, className?: string }) {

  useEffect(() => {
    document.body.classList.remove('hp-body-homepage-notconnected-cgu');
    document.body.classList.remove('hp-body-homepage-notconnected');
  }, []);
  
  return (
    <div className={`ks-mainFrame ${props.className || ''}`}>
      <div id='mainContentScreen' className='ks-content'>
        {props.children}
      </div>
    </div>
  );
}

export default MainLayout;
