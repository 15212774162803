import './PauseNotConnected.scss';
import { ReactComponent as PausePopup } from './assets/pause-popup.svg';
import { routeToPlatformHomePage } from 'components/elements/common/internalrouter/HooPowAppMenu';

function PauseNotConnected() {
  const url = new URL(window.location.href);
  const appId = url.searchParams.get("appId");

  return (
    <div className="hp-pause-popup">
      <PausePopup />
      <div className='authentication-btn'>
        <button className="mainBtn" onClick={() => document.location.href = `${routeToPlatformHomePage()}/embedded/vw/auth?appId=${appId}&initialState=SIGNUP`}>S’INSCRIRE</button>
        <button className="mainBtn ks-boxShadow" onClick={() => document.location.href = `${routeToPlatformHomePage()}/embedded/vw/auth?appId=${appId}&initialState=SIGNIN`}>SE CONNECTER</button>
      </div>
    </div>
  );
}

export default PauseNotConnected;
