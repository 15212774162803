import "./AwladBlocked.scss";
import { ReactComponent as Background } from './assets/awlad-popup-bkgd.svg';
import { BlockerContext, steps } from "components/utils/BlockerContext";
import { useContext } from "react";

function AwladBlocked() {
  const { setUnblockStep } = useContext(BlockerContext);
  return (
    <div className="awlad-blocked-popup">
      <Background className="awlad-blocked-popup__background" />
      <div className="awlad-blocked-popup__main">
        <p>
          Bienvenue dans la méthode autonome Awlad school.<br />
          <br />
          Interactif et ludique, ce logiciel permettra, aux petits<br />
          comme aux grands, d’acquérir les bases indispensables<br />
          de la <b>langue arabe</b>.<br />
          <br />
          Cette application est <b>100% gratuite</b>,<br />
          grâce au soutien de nos partenaires.
        </p>
        <div className="awlad-blocked-popup__main--frame">
          <p>
            Pour commencer, merci de vous connecter<br />
            avec un compte Hoopow
          </p>
          <div className="btn-group">
            <button className="mainBtn ks-boxShadow" onClick={() => setUnblockStep(steps.SIGNIN)}>SE CONNECTER</button>
            <div>
              <button className="mainBtn" onClick={() => setUnblockStep(steps.SIGNUP)}>S’INSCRIRE</button>
              <span>(Gratuit)</span>
            </div>
          </div>
        </div>
      </div>

    </div>);
}

export default AwladBlocked;
