import './EidGift.scss';
import { ReactComponent as CardPreview } from '../../assets/hand-preview-gift-card.svg';
import { ReactComponent as BtnMinus } from '../../assets/btn_minus.svg';
import { ReactComponent as BtnPlus } from '../../assets/btn_plus.svg';
import FAQ from '../elements/FAQ';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../utils/UserContext';

const what = <>Un abonnement donne accès à l’ensemble des applications de la plateforme web HooPow.<br />Inclus dans l’offre, le logiciel Awlad School (avec la création de 2 profils maximum).</>
const who = <>Après le paiement, une carte cadeau s’affichera à l’écran avec inscrit dessus le code promo.<br />Vous pourrez la télécharger et même l’imprimer si vous souhaitez l’offrir en format papier !<br />Un mail vous sera également envoyé avec des liens directs vers vos cartes.</>
const mobile = <>Rien de plus simple ! Il faut d’abord créer un compte sur <a href='https://www.hoo-pow.com'>www.hoo-pow.com</a>, c’est gratuit. Ensuite, il faut se rendre dans l’ESPACE MEMBRE, puis entrer le code dans la partie réservée.</>

const eidGiftFaqArray = [
  { question: 'Que comporte l’offre ?', answer: what },
  { question: 'Comment récupérer la carte ?', answer: who },
  { question: 'Comment utiliser le code ?', answer: mobile }
]

function EidGiftV3(props: {appId: string}) {
  const [quantity, setQuantity] = useState<number>(1);
  const [planId, setPlanId] = useState<string>('half_year_gift_plan');
  const [email, setEmail] = useState<string>('');

  const { isUserAuthenticated, userInfo } = useContext(UserContext);

  useEffect(() => {
    if (isUserAuthenticated && userInfo) {
      setEmail(userInfo.attributes.email);
    } else {
      setEmail('');
    }
  }, [userInfo, isUserAuthenticated]);

  return (
    <div className='hp-eidgift-plans-faq'>
      <div className='hp-eidgift-plans-form' >
        <div className='hp-eidgift-plans-form-group' >
          <label>Choisissez la formule à offrir</label>
          <div className='hp-eidgift-plans-form-plans'>
            <div className={`hp-eidgift-plans-form-plan ks-clickable ${planId === 'half_year_gift_plan' ? 'selected' : ''}`} onClick={() => setPlanId('half_year_gift_plan')} >
              6 MOIS / 45€
                </div>
            <div className={`hp-eidgift-plans-form-plan ks-clickable ${planId === 'year_gift_plan' ? 'selected' : ''}`} onClick={() => setPlanId('year_gift_plan')}>
              12 MOIS / 72€
                </div>
          </div>
        </div>
        <div className='hp-eidgift-plans-form-group' >
          <label>Choisissez le nombre d’abonnement</label>
          <div className='hp-eidgift-plans-form-quantity' >
            <BtnMinus className={`ks-clickable ${quantity === 1 ? 'ks-disabled' : ''}`} onClick={() => setQuantity(prev => prev > 1 ? prev - 1 : 1)} />
            <label>{quantity}</label>
            <BtnPlus className={'ks-clickable'} onClick={() => setQuantity(prev => prev + 1)} />
          </div>
        </div>
        <div className='hp-eidgift-plans-form-group ks-form-group' >
          <input type='email' className="ks-form-control" placeholder='Tapez-ici votre email'
            value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className='hp-eidgift-plans-form-group' >
          <button className='mainBtn' onClick={async () => {
            document.location.href = "/embedded/vw/checkout?appId=" + props.appId + "&productId=" + planId + "&quantity=" + quantity + "&itemId=full&urlCallback=/embedded/gift?tab=1"
          }} >Valider</button>
        </div>
      </div>
      <FAQ faqArray={eidGiftFaqArray} />
      <CardPreview className='hp-eidgift-card-preview' />
    </div>
  );
}

export default EidGiftV3;
