import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import './Plans.scss';
import { ReactComponent as StripeMention } from '../../assets/stripe_mention.svg';
import UserDS from '../../datastore/UserDS';
import { stripe } from '../utils/Stripe';
import { UserContext } from '../utils/UserContext';

const planDescriptionPerType: any = {
  monthly_plan: (<>Paiement mensuel automatique.<br />Vous pouvez arrêter à tout moment, sans frais.</>),
  yearly_plan: (<>Payez une fois*, maintenant, et économisez 48€<br />par rapport à l’abonnement mensuel !</>),
  yearly_plus_plan: (<>Supportez la production et recevez chaque semaine<br />le journal de bord digital de la production ! (Interview,<br />coulisses, roughs, pre-tests et plus encore…)</>)

}

const PlanDesc = (props: {
  title: any, price: number, mention?: string,
  onClick: MouseEventHandler<HTMLDivElement>, className: string
}) => {
  const { title, price, mention, className, onClick } = props;
  return (
    <div className={'hp-plans-desc ks-clickable ks-boxShadow ' + className} onClick={onClick}>
      <span className='hp-plans-formula'>{title}</span>
      <div className='hp-plans-currency-price'>
        <span className='hp-plans-currency'>€</span>
        <span className='hp-plans-price '>
          {price}
        </span>
        {mention && (<>
          <span className='hp-plans-price-mention'>{mention}</span>
        </>)}
      </div>
    </div>);
}

function Plans() {
  const [selectedPlan, setSelectedPlan] = useState<string>('yearly_plan');
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>({ email: '', name: '' });

  const { isUserAuthenticated, userInfo } = useContext(UserContext);

  useEffect(() => {
    if (isUserAuthenticated && userInfo) {
      setUserDetails({
        name: userInfo.attributes.name,
        email: userInfo.attributes.email,
      });
    } else {
      setUserDetails({
        name: '',
        email: '',
      });
    }
  }, [userInfo, isUserAuthenticated]);

  return (
    <div className="hp-plans">
      <div>CHOISISSEZ VOTRE FORMULE D’ABONNEMENT</div>
      <div className='hp-plans-proposition'>
        <PlanDesc title={<>FORMULE<br />MENSUELLE</>} price={10}
          className={selectedPlan === 'monthly_plan' ? 'hp-plan-selected' : ''} onClick={() => setSelectedPlan('monthly_plan')} />
        <PlanDesc title={<>FORMULE<br />ANNUELLE</>} price={72}
          className={selectedPlan === 'yearly_plan' ? 'hp-plan-selected' : ''} onClick={() => setSelectedPlan('yearly_plan')} />
        <PlanDesc title={<>FORMULE<br />++ANNUELLE</>} price={100}
          className={selectedPlan === 'yearly_plus_plan' ? 'hp-plan-selected' : ''} mention='OU PLUS' onClick={() => setSelectedPlan('yearly_plus_plan')} />
      </div>
      <div className='hp-plans-planDescription'>
        <div className='hp-plans-planDescription-text'>{planDescriptionPerType[selectedPlan]}</div>
        <button className='mainBtn' disabled={loading} onClick={async () => {
          setLoading(true);
          const dataSession = await UserDS.createSession(selectedPlan, userDetails.email, userDetails.name);
          await stripe.redirectToCheckout({
            sessionId: dataSession.sessionId
          });
          setLoading(false);
        }}>CHOISIR</button>
        <StripeMention />
      </div>
    </div>
  );
}

export default Plans;
