import { MouseEventHandler, useState } from 'react';
import './FAQ.scss';
import { ReactComponent as IconPlus } from '../../assets/close-btn.svg';

const what = <>C’est une plateforme ludo-éducative entièrement dédiée à la famille musulmane.<br/>Un mélange de divertissement intelligent et d’apprentissage ludique dans un environnement sain et éthique.</>
const who = <>Dirigé par Norédine Allam, l’auteur des séries BDouin, l’équipe est constituée d’un groupe de professionnels engagés dans la production de contenu utile pour la communauté. La méthode Awlad School est supervisée par un directeur pédagogique titulaire d’une <i>Ijaza</i>, certifiant de sa mémorisation des 7 différents types de lecture du Coran, et diplômé des langues de l’Université de la Sorbonne.</>
const mobile = <>HooPow est une plateforme web, disponible uniquement sur internet.<br/>Elle a été conçue pour une utilisation optimale sur ordinateur et tablette (idéal pour les enfants). La version responsive, avec une image qui s’adapte au format téléphone, est en cours de développement et devrait arriver avant cet été, incha Allah.</>
const whatnext = <>HooPow débute fort avec déjà 5 univers accessibles : Awlad School, Muslim Show, Famille Foulane, Les Quizs et Les minis guides du BDouin.<br/>Notre mission, pour cette année, sera donc de mettre régulièrement à jour du contenu dans chacun de ces univers, avec comme objectif :<br/>Chaque jour, une petite actu - Chaque semaine, une jolie actu - Chaque mois, une grosse actu ! : )</>
const price = <>Au mois : 10€ (le prix d’un livre) - À L’année : 72€ (le prix d’une boîte de Lego : )<br/>Un abonnement peut être installé sur 5 appareils (d’utilisateurs de la même famille) et permet la création de 2 profils différents sur la méthode Awlad School.</>

const defaultFaqArray = [
  { question: 'HOOPOW, c’est quoi ?', answer: what },
  { question: 'HOOPOW, c’est qui ?', answer: who },
  { question: 'Est-ce disponible sur mobile ?', answer: mobile },
  { question: 'Quoi de prévu cette année ?', answer: whatnext },
  { question: 'Combien ça coûte ?', answer: price },
]

const FAQ = (props: {
  question: string, answer: any, onClick: MouseEventHandler<HTMLDivElement>, className: string
}) => {
  const { question, answer, className, onClick } = props;
  return (
    <div className={' hp-faqs-question-answer ' + className}>
      <div className='hp-faqs-header ks-clickable' onClick={onClick}>
        <div className='hp-faqs-question'>{question}</div>
        <IconPlus className='hp-faqs-plus' />
      </div>
      <div className='hp-faqs-answer'><div className='hp-faqs-answer-text'>{answer}</div></div>
    </div>);
}

function FAQs(props: {faqArray?: any}) {
  const [openQuestion, setOpenQuestion] = useState<number>();
  const faqArrayToDisplay = props.faqArray ? props.faqArray : defaultFaqArray;

  return (
    <div className="hp-faq">
      {faqArrayToDisplay.map((questionAnswer: any, index: number) => (
        <FAQ key={'faq_' + index} question={questionAnswer.question} answer={questionAnswer.answer}
          className={openQuestion === index ? 'hp-faq-selected' : ''} onClick={() => setOpenQuestion(prev => prev === index ? undefined : index)} />
      ))}
    </div>
  );
}

export default FAQs;
