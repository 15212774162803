import React from 'react';

import {AuthState, onAuthUIStateChange, Translations} from '@aws-amplify/ui-components';

import './App.scss';
import AuthenticationComponent from './components/Authentication';
import HomepageComponent from './components/Homepage';
import CGUComponent from './components/CGU';

import {BrowserRouter as Router, Redirect, Switch} from "react-router-dom";
import {AmplifyAuthenticator} from '@aws-amplify/ui-react';

import MainLayout from './components/MainLayout';
import MemberComponent from './components/pages/Member';
import SubscribeComponent from './components/pages/Subscribe';
import GiftSpaceComponent from './components/pages/GiftSpace';
import EidGiftPageComponent from './components/pages/EidGiftPage';
import GiftCouponComponent from './components/pages/GiftCoupon';
import ContactUsComponent from './components/pages/ContactUs';
import PrivateRoute from './components/elements/PrivateRoute';
import TitledRoute from './components/elements/common/internalrouter/TitledRoute';
import {Auth, I18n} from 'aws-amplify';
import LayoutV2 from './components/layout/LayoutV2';
import UserContextProvider from './components/utils/UserContext';
import MainConfigContext from './components/utils/MainConfigContext';
import EmbeddedLayout from './components/layout/EmbeddedLayout';
import CheckoutForm from './components/elements/CheckoutForm';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import UserAnnouncement from './components/elements/common/phoneblocked/UserAnnouncement';
import EmbeddedAuthenticationComponent from './components/auth/EmbeddedAuthentication';
import EmbeddedMemberComponent from './components/embedded/Member';
import EmbeddedPauseMessgaeComponent from './components/embedded/PauseNotConnected';
import GiftSpaceEmbedded from './components/embedded/GiftSpaceEmbedded';
import EditUser from './components/embedded/EditUser';
import MyOrders from './components/embedded/MyOrders';
import ChangePayment from './components/embedded/components/ChangePayment';
import ExplanationTemplate from './components/embedded/components/ExplanationTemplate';
import {isEmbedded} from './utils/Constants';
import {explanationInfo} from './components/embedded/utils/Constants';
import EmbeddedContactUs from './components/embedded/components/EmbeddedContactUs';
import BlockerContext from 'components/utils/BlockerContext';
import PasswordReset from 'components/auth/PasswordReset';
import AppSwitcher from 'components/AppSwitcher';
import AboutComponent from "./components/Foulane/About";

I18n.setLanguage('fr-FR')

I18n.putVocabulariesForLanguage("fr-FR", {
    [Translations.RESET_YOUR_PASSWORD]: "Mot de passe oublié ?",
    [Translations.EMAIL_LABEL]: "Email :",
    [Translations.EMAIL_PLACEHOLDER]: "Tapez ici votre email",
    [Translations.BACK_TO_SIGN_IN]: "Retour à la page de connexion",
    [Translations.SEND_CODE]: "Envoyer le code",
    [Translations.CODE_LABEL]: "Code de vérification",
    [Translations.CODE_PLACEHOLDER]: "Entrez votre code",
    [Translations.NEW_PASSWORD_LABEL]: "Nouveau mot de passe",
    [Translations.NEW_PASSWORD_PLACEHOLDER]: "Tapez ici votre nouveau mot de passe",
    [Translations.SUBMIT]: "VALIDER"
});

function App() {
    const [authState, setAuthState] = React.useState<AuthState>();
    const embedded = isEmbedded();

    if (process.env.REACT_APP_FEATURE_NEW_DESIGN_V3 === '1') document.body.classList.add('bodyV3')
    else document.body.classList.remove('bodyV3')

    React.useEffect(() => {

        if (authState === undefined) {
            Auth.currentAuthenticatedUser({bypassCache: true}).then((data) => {
                const userGroup = data.signInUserSession.accessToken.payload['cognito:groups'];
                setAuthState(AuthState.SignedIn);
                if (userGroup && userGroup.indexOf('awlad') > -1) {
                    document.location.href = "https://awlad-school.com"
                }
            }).catch((error) => {
                console.log('Anonymous user.', error);
            });
        }

        return onAuthUIStateChange((nextAuthState) => {
            setAuthState(nextAuthState);
            Auth.currentAuthenticatedUser({bypassCache: true}).then((data) => {
                const userGroup = data.signInUserSession.accessToken.payload['cognito:groups'];
                setAuthState(AuthState.SignedIn);
                if (userGroup && userGroup.indexOf('awlad') > -1) {
                    document.location.href = "https://awlad-school.com"
                }
            }).catch((error) => {
                console.log('Anonymous user.', error);
            });
        });
    }, [authState]);

    const isAuthenticated = () => {
        return authState === AuthState.SignedIn || process.env.REACT_APP_FEATURE_FREEMIUM === '1';
    }

    return (
        <>
            {!embedded && (
                <UserAnnouncement/>
            )}
            {isAuthenticated() ? (
                <UserContextProvider>
                    <BlockerContext>
                        <MainConfigContext>
                            <React.Suspense fallback={<></>}>
                                <Router>
                                    <Switch>
                                        <TitledRoute path="/" pageName="Accueil" exact render={() => AppSwitcherCmp()}/>
                                        <TitledRoute path="/home" pageName="Accueil" exact
                                                     render={(routeProps: any) => Homepage(routeProps, isAuthenticated())}/>
                                        <TitledRoute path="/cgu" pageName="CGU" exact render={CGU}/>
                                        <TitledRoute path="/login" pageName="Se connecter" exact render={SignIn}/>
                                        <TitledRoute path="/signin" pageName="Se connecter" exact render={SignInV2}/>
                                        <TitledRoute path="/embedded/pausemessage" pageName="Pause" exact
                                                     render={EmbeddedPauseMessage}/>
                                        <TitledRoute path="/embedded/vw/auth" pageName="S'inscrire" exact
                                                     render={EmbeddedAuthentication}/>
                                        <TitledRoute path="/embedded/vw/edituser" pageName="Édition du profil" exact
                                                     render={EmbeddedUserEdition}/>
                                        <TitledRoute path="/embedded/vw/checkout" pageName="Paiement" exact
                                                     render={CheckoutEmbedded}/>
                                        <TitledRoute path="/embedded/vw/signin" pageName="Se connecter" exact
                                                     render={SignInEmbedded}/>
                                        <TitledRoute path="/embedded/resetpassword" pageName="Mot de passe oublié" exact
                                                     render={EmbeddedResetPassword}/>

                                        <TitledRoute path="/foulane/" pageName="À propos FOULANE" exact render={About}/>
                                        <TitledRoute path="/foulane/about" pageName="À propos FOULANE" exact
                                                     render={About}/>
                                        <TitledRoute path="/foulane/novels" pageName="Romans FOULANE" exact
                                                     render={About}/>
                                        <TitledRoute path="/foulane/audios" pageName="Audios FOULANE" exact
                                                     render={About}/>
                                        <TitledRoute path="/foulane/games" pageName="Jeux FOULANE" exact
                                                     render={About}/>

                                        <TitledRoute path="/embedded/gift" pageName='Abonnement Cadeau' exact
                                                     render={GiftEmbedded}/>
                                        <TitledRoute path="/embedded/orders" pageName="Mes achats" exact
                                                     render={EmbeddedMyOrders}/>
                                        <TitledRoute path="/embedded/vw/member" pageName="Espace membre" exact
                                                     render={EmbeddedMember}/>
                                        <TitledRoute path="/embedded/vw/changePayment" pageName="Espace membre" exact
                                                     render={EmbeddedChangeCard}/>
                                        <TitledRoute path="/embedded/v2/who" pageName="BDouin, who is it?" exact
                                                     render={WhoEmbedded}/>
                                        <TitledRoute path="/embedded/v2/what" pageName="HooPow, what is it?" exact
                                                     render={WhatEmbedded}/>
                                        <TitledRoute path="/embedded/v2/supportus" pageName="Support Us" exact
                                                     render={SupportUsEmbedded}/>
                                        <TitledRoute path="/embedded/v2/contactus" pageName="Contact Us" exact
                                                     render={ContactUsEmbedded}/>

                                        <TitledRoute path="/coupon/:couponId" pageName='Coupon Cadeau' exact
                                                     render={GiftCoupon}/>
                                        <TitledRoute path="/eidgift" pageName='Abonnement Cadeau' exact
                                                     render={EidGiftPage}/>
                                        {process.env.REACT_APP_FEATURE_FREEMIUM === '1' ? (
                                            <TitledRoute path="/signup" pageName="S'inscrire" exact render={SignUp}/>
                                        ) : (<Redirect from="/signup" to="/" exact/>)}
                                        <PrivateRoute path="/cguconnected" pageName="CGU" exact
                                                      render={(routeProps: any) => CGUConnected(routeProps, isAuthenticated())}
                                                      authed={isAuthenticated()}/>
                                        <PrivateRoute path="/member" pageName='Mon espace membre' exact render={Member}
                                                      authed={isAuthenticated()}/>
                                        <PrivateRoute path="/subscribe" pageName='Mon espace membre' exact
                                                      render={Subscribe} authed={isAuthenticated()}/>
                                        <PrivateRoute path="/giftspace" pageName='Espace Carte Cadeau' exact
                                                      render={GiftSpace} authed={isAuthenticated()}/>
                                        <PrivateRoute path="/contactus" pageName='Nous contacter' exact
                                                      render={ContactUs} authed={isAuthenticated()}/>
                                    </Switch>
                                </Router>
                            </React.Suspense>
                        </MainConfigContext>
                    </BlockerContext>
                </UserContextProvider>
            ) : (
                <>
                    {authState === undefined && (
                        <AmplifyAuthenticator/>
                    )}
                </>)}
        </>
    );
}

export default App;

const SignIn = (props: any) => (
    <MainLayout>
        <AuthenticationComponent {...props} initialAuthState={AuthState.SignIn}/>
    </MainLayout>
);

const AppSwitcherCmp = () => (
    <AppSwitcher/>
);

const SignInEmbedded = (props: any) => {
        const url = new URL(window.location.href);
        const onMobile: boolean = "true" === url.searchParams.get("isMobile");

        return (
            <EmbeddedLayout {...props} onMobile={onMobile}>
                <AuthenticationComponent {...props} initialAuthState={AuthState.SignIn}/>
            </EmbeddedLayout>)
    }
;

const SignInV2 = (props: any) => (
    <LayoutV2 menuOpen={true} {...props}>
        <AuthenticationComponent {...props} initialAuthState={AuthState.SignIn}/>
    </LayoutV2>
);

const SignUp = (props: any) => (
    <LayoutV2 menuOpen={true} {...props}>
        <AuthenticationComponent {...props} initialAuthState={AuthState.SignUp}/>
    </LayoutV2>
);

const EmbeddedResetPassword = (props: any) => {
        const url = new URL(window.location.href);
        const onMobile: boolean = "true" === url.searchParams.get("isMobile");

        return (
            <EmbeddedLayout {...props} onMobile={onMobile}>
                <PasswordReset {...props} successCallback={() => document.location.href = "/login"}/>
            </EmbeddedLayout>)
    }
;

const EmbeddedAuthentication = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (
        <EmbeddedLayout {...props} onMobile={onMobile}>
            <EmbeddedAuthenticationComponent {...props} onMobile={onMobile} initialAuthState={AuthState.SignUp}/>
        </EmbeddedLayout>
    )

};

const EmbeddedPauseMessage = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (
        <EmbeddedLayout {...props} onMobile={onMobile}>
            <EmbeddedPauseMessgaeComponent {...props} initialAuthState={AuthState.SignUp}/>
        </EmbeddedLayout>)
};

const EmbeddedUserEdition = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (
        <EmbeddedLayout {...props} onMobile={onMobile}>
            <EditUser {...props} />
        </EmbeddedLayout>
    )
};

const EmbeddedMyOrders = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (
        <EmbeddedLayout {...props} onMobile={onMobile}>
            <MyOrders {...props} />
        </EmbeddedLayout>
    )
};

const EmbeddedMember = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (
        <EmbeddedLayout {...props} onMobile={onMobile} className="hp-embedded-member-space">
            <EmbeddedMemberComponent {...props} />
        </EmbeddedLayout>
    )
};

const EmbeddedChangeCard = (props: any) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '', {locale: 'fr'});

    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");
    return (
        <EmbeddedLayout {...props} onMobile={onMobile} className="hp-embedded-change-card">
            <Elements stripe={stripePromise}>
                <ChangePayment {...props} />
            </Elements>
        </EmbeddedLayout>)
};

const CheckoutEmbedded = (props: any) => {
    const url = new URL(window.location.href);
    const productId = url.searchParams.get("productId");
    const itemId = url.searchParams.get("itemId");
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '', {locale: 'fr'});
    return (
        <EmbeddedLayout {...props} className="payment-layout" onMobile={onMobile}>
            <Elements stripe={stripePromise}>
                <CheckoutForm {...props} productId={productId} itemId={itemId} onMobile={onMobile}/>
            </Elements>
        </EmbeddedLayout>)
};

const GiftEmbedded = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (<EmbeddedLayout onMobile={onMobile} {...props} className="payment-layout">
            <GiftSpaceEmbedded {...props} />
        </EmbeddedLayout>
    )
};

const WhoEmbedded = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");
    return (<EmbeddedLayout {...props} onMobile={onMobile} className="hp-who hp-explanation">
            <ExplanationTemplate {...props} info={explanationInfo.who}/>
        </EmbeddedLayout>
    )
};

const WhatEmbedded = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (
        <EmbeddedLayout {...props} onMobile={onMobile} className="hp-what hp-explanation">
            <ExplanationTemplate {...props} info={explanationInfo.what}/>
        </EmbeddedLayout>
    )
};

const SupportUsEmbedded = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (
        <EmbeddedLayout {...props} onMobile={onMobile} className="hp-supportus hp-explanation">
            <ExplanationTemplate {...props} info={explanationInfo.supportus}/>
        </EmbeddedLayout>)
};

const ContactUsEmbedded = (props: any) => {
    const url = new URL(window.location.href);
    const onMobile: boolean = "true" === url.searchParams.get("isMobile");

    return (
        <EmbeddedLayout {...props} onMobile={onMobile} className="hp-embeddedcontactus hp-explanation">
            <EmbeddedContactUs {...props} />
        </EmbeddedLayout>
    )
};

const Homepage = (props: any, isAuthenticated: boolean) => (
    <HomepageComponent {...props} isAuthenticated={isAuthenticated}/>
);

const CGU = (props: any, isAuthenticated: boolean) => (
    <CGUComponent {...props} isAuthenticated={isAuthenticated}/>
);

const CGUConnected = (props: any, isAuthenticated: boolean) => (
    <LayoutV2 isAuthenticated={isAuthenticated} className='hp-cgu-connected' {...props} >
        <CGUComponent {...props} isAuthenticated={isAuthenticated} isEmbedded={true}/>
    </LayoutV2>
);

const Member = (props: any) => (
    <LayoutV2 menuOpen={true} {...props} >
        <MemberComponent {...props} />
    </LayoutV2>
);

const Subscribe = (props: any) => (
    <LayoutV2 menuOpen={true} {...props} >
        <SubscribeComponent {...props} />
    </LayoutV2>
);


const GiftSpace = (props: any, isAuthenticated: boolean) => (
    <LayoutV2 className='hp-giftspace-mainlayout' menuOpen={true} isAuthenticated={isAuthenticated} {...props} >
        <GiftSpaceComponent {...props} isAuthenticated={isAuthenticated}/>
    </LayoutV2>
);

const GiftCoupon = (props: any, isAuthenticated: boolean) => (
    <MainLayout className='hp-giftspace-mainlayout'>
        <GiftCouponComponent {...props} />
    </MainLayout>
);

const EidGiftPage = (props: any, isAuthenticated: boolean) => (
    <LayoutV2 className='hp-giftspace-mainlayout' menuOpen={true} isAuthenticated={isAuthenticated} {...props} >
        <EidGiftPageComponent {...props} />
    </LayoutV2>
);

const ContactUs = (props: any, isAuthenticated: boolean) => (
    <LayoutV2 className='hp-giftspace-mainlayout' menuOpen={true} isAuthenticated={isAuthenticated} {...props} >
        <ContactUsComponent {...props} />
    </LayoutV2>
);


const About = (props: any, isAuthenticated: boolean) => (
    <AboutComponent {...props} isAuthenticated={isAuthenticated}/>
);