import "./BlockedModal.scss";
import "./BlockedModalMobile.scss";
import {ReactComponent as IconClose} from "assets/v3/icon-close.svg";
import {MutableRefObject, useCallback, useContext, useEffect, useRef} from "react";
import {UserContext} from "components/utils/UserContext";
import {ClockLoader as Loader} from "react-spinners";
import IFrame from "./IFrame";
import {steps} from "components/utils/BlockerContext";
import {routeToPlatformHomePage} from "../internalrouter/HooPowAppMenu";
import AwladBlocked from "./AwladBlocked";

const extraStyle: steps[] = [steps.AWLAD_SCHOOL, steps.SIGNUP, steps.SIGNIN, steps.GIFT, steps.EDITPROFIL, steps.ORDERS, steps.MEMBER, steps.WHO, steps.WHAT, steps.SUPPORTUS, steps.CONTACTUS];

function BlockedModal(props: { open: boolean, setOpen: Function, unblockStep: steps, setUnblockStep: Function, nextStep: MutableRefObject<steps>, onMobile?: boolean }) {
    const {refreshUserInfo, isUserAuthenticated, userHasAccess} = useContext(UserContext);
    const {unblockStep, setUnblockStep, nextStep} = props;

    const isAuthenticatedRef = useRef<boolean>(isUserAuthenticated);

    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.origin === routeToPlatformHomePage()) {
                if (event.data.step === 'signedup' || event.data.step === 'signedin') {
                    isAuthenticatedRef.current = true;
                    setUnblockStep(steps.LOADING)
                    refreshUserInfo(() => {
                        document.location.reload();
                    });
                }
                if (event.data.step === 'edition_success') {
                    refreshUserInfo();
                }
                if (event.data.step === 'payment_done_successfully') {
                    document.location.reload();
                }
                if (event.data.step === 'subscription') {
                    setUnblockStep(steps.SUBSCRIPTION_PAYMENT);
                }
            }
        });
    }, [refreshUserInfo, userHasAccess, nextStep, setUnblockStep]);

    useEffect(() => {
        isAuthenticatedRef.current = isUserAuthenticated;
    }, [isUserAuthenticated]);

    const viewSelector = useCallback(() => {
        console.log("unblockStep", steps[unblockStep])
        if (unblockStep !== steps.SIGNUP
            && unblockStep !== steps.SIGNIN
            && unblockStep !== steps.LOADING
            && unblockStep !== steps.AWLAD_SCHOOL
            && unblockStep !== steps.SUPPORTUS
            && !isAuthenticatedRef.current) {
            nextStep.current = unblockStep;
            setUnblockStep(steps.SIGNUP);
            return;
        }
        switch (unblockStep) {
            case steps.RELOAD:
                document.location.reload();
                return (<></>)
            case steps.SIGNIN:
            case steps.SIGNUP:
                return (<>
                    <IFrame key={'iframe-signin'} id='iframe-signin' className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/vw/auth?appId=${process.env.REACT_APP_ID}&initialState=${steps[unblockStep]}`}
                            title='Signup'/>
                </>)
            case steps.EDITPROFIL:
                return (<>
                    <IFrame key={'iframe-edituser'} id='iframe-edituser' className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/vw/edituser?appId=${process.env.REACT_APP_ID}`}
                            title='Edit User'/>
                </>)
            case steps.GIFT:
                return (<>
                    <IFrame key={'iframe-gift'} id='iframe-gift'
                            className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/gift`}
                            title='Gift'/>
                </>)
            case steps.WHO:
                return (<>
                    <IFrame key={'iframe-who'} id='iframe-who'
                            className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/v2/who`}
                            title='Who'/>
                </>)
            case steps.WHAT:
                return (<>
                    <IFrame key={'iframe-what'} id='iframe-what'
                            className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/v2/what`}
                            title='What'/>
                </>)
            case steps.SUPPORTUS:
                return (<>
                    <IFrame key={'iframe-supportus'} id='iframe-supportus'
                            className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/v2/supportus`}
                            title='Support Us'/>
                </>)
            case steps.CONTACTUS:
                return (<>
                    <IFrame key={'iframe-contactus'} id='iframe-contactus'
                            className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/v2/contactus`}
                            title='Contact Us'/>
                </>)
            case steps.ORDERS:
                return (<>
                    <IFrame key={'iframe-orders'} id='iframe-orders'
                            className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/orders`}
                            title='Gift'/>
                </>)
            case steps.SUBSCRIPTION_PAYMENT:
                return (<>
                    <IFrame key={'iframe-subpayment'} id='iframe-subpayment'
                            className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/vw/checkout?appId=${process.env.REACT_APP_ID}&productId=${"monthly_plan_v3"}&itemId=full`}
                            title='Subscription'/>
                </>);
            case steps.LOADING:
                return (
                    <div style={{position: "absolute", bottom: "50px", zIndex: 9}} id="iframe-loader"
                         className="hideDiv">
                        <Loader size={60} color="black"/>
                    </div>);
            case steps.AWLAD_SCHOOL:
                return (<AwladBlocked/>);
            case steps.MEMBER:
            default:
                return (<>
                    <IFrame key={'iframe-member'} id='iframe-member'
                            className="iframe-steps"
                            src={`${routeToPlatformHomePage()}/embedded/vw/member?appId=${process.env.REACT_APP_ID}`}
                            title='Espace Membre'/>
                </>);
        }
    }, [unblockStep, nextStep, setUnblockStep]);

    const closeModal = () => {
        props.setOpen(false);
        setUnblockStep(steps.MEMBER);
    }

    return (
        <>
            {props.open && (
                <div
                    className={`hp-blockedModal-popup ${(unblockStep && steps[unblockStep]) || "SUBSCRIPTION"} ${extraStyle.indexOf(unblockStep) > -1 && "extra-step"} ` + (props.onMobile ? "mobile-only" : "desktop-only")}>
                    <div className="hp-blockedModal-popup__wrapper">
                        <IconClose className="hp-blockedModal-popup__close ms-clickable" onClick={closeModal}/>
                        {viewSelector()}
                    </div>
                </div>
            )}
        </>);
}

export default BlockedModal;
