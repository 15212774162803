import ApiWrapper from '../../../../datastore/ApiWrapper';
import { TMainConfig } from '../../../../types'

const apiName = 'PlatformRestApi';

export default class HooPowDS {

  static getPlatformDef = async (): Promise<TMainConfig> => {
    return ApiWrapper.get(apiName, '/main/first');
  };

  static getUserAccess = async (productId: string): Promise<any> => {
    return ApiWrapper.get(apiName, '/access/' + productId);
  };

}

