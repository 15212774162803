import {useEffect, useState} from "react";
import '../auth/SignIn.scss';
import {Auth} from 'aws-amplify';
import {allowedToEmbbedFrame} from "../../utils/Constants";
import PasswordField from "../auth/PasswordField";
import {ReactComponent as LeftImage} from "../../assets/v3/login-right-img.svg"
import PasswordReset from "components/auth/PasswordReset";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {ReactComponent as CheckedIcon} from '../../assets/v3/checked-icon.svg'
import {ReactComponent as WarningIcon} from '../../assets/v3/warning-icon.svg'
import {ReactComponent as UncheckedIcon} from '../../assets/v3/cancel-icon.svg'

type TField = "name" | "email" | "password" | "oldPassword" | "avatarId";

enum state {
    VALID, INVALID, UNKOWN
}

const CustomSignIn = (props: any) => {
    const [showPasswordReset, setShowPasswordReset] = useState<boolean>();
    const [loading, setLoading] = useState(false);
    const urlParams = new URLSearchParams(props.location.search);
    const appId: any = urlParams.get('appId');
    const [errors, setErrors] = useState<any>({});
    useEffect(() => {
        document.body.classList.remove('hp-body-homepage-notconnected');
    }, []);

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const handleInputChange = (e: any, prop: string) => {
        setFormData({
            ...formData,
            [prop]: e.target.value
        });
    };
    const signInClick = async () => {
        setLoading(true);
        try {
            await Auth.signIn(formData.email, formData.password);
            if (window.parent) {
                console.log(appId);
                window.parent.postMessage({'step': 'signedin'}, allowedToEmbbedFrame(appId));
            }
        } catch (error: any) {
            let errorMessage: string;
            if (error.code && error.code === 'NotAuthorizedException') {
                errorMessage = 'L’adresse e-mail ou le mot de passe est invalide.';
                props.onMobile ?
                    toast.error(errorMessage, {position: toast.POSITION.TOP_CENTER}) :
                    setErrors((prev: any) => ({...prev, email: errorMessage}))
                setFormDataState(prev => ({...prev, email: state.INVALID, password: state.INVALID}))
            } else if (error.code === "InvalidParameterException" && error.message.indexOf("Member must satisfy regular expression pattern") > -1) {
                errorMessage = "Mauvais format d'email.";
                setFormDataState(prev => ({...prev, email: state.INVALID}))
                props.onMobile ?
                    toast.error(errorMessage, {position: toast.POSITION.TOP_CENTER}) :
                    setErrors((prev: any) => ({...prev, email: errorMessage}))
            } else {
                toast.error(error.message, {position: toast.POSITION.TOP_CENTER});
            }
        }
        setLoading(false);
    }

    const forgotPassword = () => {
        setShowPasswordReset(true);
    }

    useEffect(() => {
        document.title = 'HOO POW | Login';
    }, []);

    useEffect(() => {
        // Configuring the notification
        toast.configure();
    }, []);

    const [formDataState, setFormDataState] = useState<{ email: state, password: state }>({
        email: state.UNKOWN,
        password: state.UNKOWN
    });

    useEffect(() => {
        setFormDataState(prev => ({
            ...prev, email: getFieldValidity("email", formData.email)
        }))
    }, [formData.email]);

    useEffect(() => {
        setFormDataState(prev => ({
            ...prev, password: getFieldValidity("password", formData.password)
        }))
    }, [formData.password]);
    const getFieldValidity = (field: TField, value: string) => {
        switch (field) {
            //TODO:replace "false" with the appropriate condition for INVALID
            case "email":
                return value.length <= 0 ? state.UNKOWN : false ? state.INVALID : state.VALID;
            case "password":
                return value.length <= 0 ? state.UNKOWN : false ? state.INVALID : state.VALID;
            case "oldPassword":
                return value.length <= 0 ? state.UNKOWN : false ? state.INVALID : state.VALID;
            default:
                return state.UNKOWN;
        }
    };
    const stateToClassName = (s: state) => (" " + (s === state.VALID ? "valid" : s === state.INVALID ? "invalid" : "unknown") + " ");
    return (<>
            <div className={`ks-form-left ${showPasswordReset && 'passwordResetPage'}`}>
                {!showPasswordReset ? (<>
                    <div className="ks-sign-in" slot="sign-in">
                        <div className="ks-login">
                            <form className='ks-form'>
                                <div className={"ks-form-group" + stateToClassName(formDataState.email)}>
                                    <CheckedIcon className="checked"/>
                                    <UncheckedIcon className="unchecked"/><WarningIcon className="warning"/>
                                    <label className="ks-form-label">E-mail :</label>
                                    <input type='email' placeholder="Tapez ici votre email"
                                           onChange={(e) => handleInputChange(e, 'email')}
                                           className="ks-form-control"
                                    />
                                    <div
                                        className={"ks-form-error ks-form-error__small ks-form-error__overflow"}>{errors.email}</div>
                                </div>
                                <div className={"ks-form-group" + stateToClassName(formDataState.password)}>
                                    <CheckedIcon className="checked"/>
                                    <UncheckedIcon className="unchecked"/><WarningIcon className="warning"/> <label
                                    className="ks-form-label">Mot de passe :</label>
                                    <PasswordField placeholder="Tapez ici votre mot de passe" autoComplete="password"
                                                   onChange={(e: any) => handleInputChange(e, 'password')}
                                                   className="ks-form-control"/>
                                    <div
                                        className={"ks-form-error ks-form-error__small ks-form-error__overflow"}>{errors.password}</div>
                                </div>
                            </form>
                            <div className="ks-formBtn">
                                <button className="mainBtn" type="submit" onClick={() => {
                                    signInClick()
                                }} disabled={formData.email === '' || formData.password === '' || loading}>
                                    VALIDER
                                </button>
                                <button className="ks-forgetBtn ks-clickable" onClick={() => {
                                    forgotPassword()
                                }} disabled={loading}>
                                    {`Mot de passe oublié ? ${!props.onMobile ? "Cliquez-ici" : ""}`}
                                </button>
                            </div>
                        </div>
                    </div>
                </>) : (<>
                    <PasswordReset {...props} successCallback={() => setShowPasswordReset(false)}/>
                </>)}
            </div>
            {!props.onMobile ? <div className="ks-form-right ks-signin-img">
                <LeftImage/>
            </div> : <></>}
        </>
    );
}
export default CustomSignIn;