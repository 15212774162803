import "./About.scss"
import {ReactComponent as HoopowLogo} from '../../assets/hoopow-logo.svg';
import {ReactComponent as BannerFoulane} from '../../assets/foulane/banner-foulane.svg';
import {ReactComponent as AboutIcon} from '../../assets/foulane/about-icon.svg';
import {ReactComponent as NovelsIcon} from '../../assets/foulane/novels-icon.svg';
import {ReactComponent as AudiosIcon} from '../../assets/foulane/audios-icon.svg';
import {ReactComponent as GamesIcon} from '../../assets/foulane/games-icon.svg';
import {ReactComponent as SiblingsInSnow} from '../../assets/foulane/siblings-snow.svg';
import WaladIcon from '../../assets/foulane/walad.png';
import BintiIcon from '../../assets/foulane/binti.png';
import OummiIcon from '../../assets/foulane/oummi.png';
import AbiIcon from '../../assets/foulane/abi.png';
import RachidIcon from '../../assets/foulane/rachid.png';
import AnassIcon from '../../assets/foulane/anass.png';
import JeromeIcon from '../../assets/foulane/jerome.png';
import MaxIcon from '../../assets/foulane/max.png';
import MamieIcon from '../../assets/foulane/mamie.png';
import StrogoffIcon from '../../assets/foulane/strogoff.png';
import NoredineImage from '../../assets/foulane/noredine.png';
import {ReactComponent as VerticalLogoFoulane} from '../../assets/foulane/logo-foulane-vertical.svg';
import {ReactComponent as PlayStoreIcon} from '../../assets/foulane/play-store.svg';
import {ReactComponent as AppStoreIcon} from '../../assets/foulane/app-store.svg';
import BlueUnderline from '../../assets/foulane/underline.png';
import React, {useState} from "react";
import {Link} from "react-router-dom";

const About = (props: any) => {
    const [menuIsVisible, setMenuIsVisible] = useState<boolean>(false);

    return (<>
        <div className={"foulane"}>
            <div className={"foulane--navbar"}>
                <div className={"container-left"}>
                    <Link to={"/"}><HoopowLogo className={"hoopow-logo"}/></Link>
                </div>
                <div className={"container-right"}>
                    <div className={"text"}>POLITIQUE DE CONFIDENTIALITÉ</div>
                    <div className={"burger " + (menuIsVisible ? "to-X" : "to-burger")}
                         onClick={() => {
                             setMenuIsVisible(prevState => !prevState);
                         }}>
                        <div className={"line first-line"}></div>
                        <div className={"line second-line"}></div>
                        <div className={"line third-line"}></div>
                    </div>
                    <div className={"burger-menu " + (menuIsVisible ? "menu-visible" : "menu-hidden")}>
                        <div className={"link current-link"}><Link to={"/foulane/about"}>À PROPOS</Link></div>
                        <div className={"link"}><Link to={"/foulane/novels"}>ROMANS</Link></div>
                        <div className={"link"}><Link to={"/foulane/audios"}>AUDIOS</Link></div>
                        <div className={"link"}><Link to={"/foulane/games"}>JEUX</Link></div>
                        <div className={"link"}><Link to={"/contactus"}>CONTACT</Link></div>
                    </div>
                </div>
            </div>
            <div className={"foulane--banner"}>
                <BannerFoulane/>
            </div>
            <div className={"foulane--links"}>
                <div className={"link current-link to-about"}>
                    <div className={'icon'}><AboutIcon/></div>
                    <div className={"title"}><Link to={"/foulane/about"}>À PROPOS</Link></div>
                    <div className={"underline"}><img src={BlueUnderline} alt={""}/></div>
                </div>
                <div className={"link to-novels"}>
                    <div className={'icon'}><NovelsIcon/></div>
                    <div className={"title"}><Link to={"/foulane/novels"}>ROMANS</Link></div>
                    <div className={"underline"}><img src={BlueUnderline} alt={""}/></div>
                </div>
                <div className={"link to-audios"}>
                    <div className={'icon'}><AudiosIcon/></div>
                    <div className={"title"}><Link to={"/foulane/audios"}>AUDIOS</Link></div>
                    <div className={"underline"}><img src={BlueUnderline} alt={""}/></div>
                </div>
                <div className={"link to-games"}>
                    <div className={'icon'}><GamesIcon/></div>
                    <div className={"title"}><Link to={"/foulane/games"}>JEUX</Link></div>
                    <div className={"underline"}><img src={BlueUnderline} alt={""}/></div>
                </div>
            </div>
            <div className={"foulane--description"}>
                <div className={"text"}>
                    <div className={"title"}>
                        ABI, OUMMI, BINTi<br/>
                        ET BIEN SÛR WALAD
                    </div>
                    <div className={"description"}>
                        vivent dans une petite maison d’un<br/>
                        quartier périphérique de Foulanville.<br/>
                        Entre les histoires de cour de récré, les activités d’été dans le jardin et les sorties
                        familiales, Walad va vous plonger dans l’intimité d’une famille musulmane qui n’aspire qu’à une
                        seule chose :<br/>
                        le bonheur et la tranquillité.<br/>
                    </div>
                    <div className={"info"}>
                        <div><strong>Auteur :</strong> Norédine Allam</div>
                        <div><strong>Éditeur :</strong> BDouin</div>
                        <div>Créée en 2019</div>
                    </div>
                </div>
                <div className={"image"}>
                    <SiblingsInSnow/>
                </div>
            </div>
            <div className={"foulane--characters"}>
                <div className={"title"}>
                    Les personnaages
                </div>
                <div className={"container"}>
                    <div className={'character first-line'}>
                        <div className={'name'}>WALAD</div>
                        <div className={'icon'}><img src={WaladIcon} alt={""}/></div>
                        <div className={"description"}>Le cadet de la famille, mais aussi le narrateur de la série !
                            Intelligent, malicieux, sensible, chef de bande malgré lui, Walad est surtout un petit
                            garçon sensible qui sait reconnaître ses erreurs et qui ne désire qu’une seule chose :
                            apprendre à grandir.
                        </div>
                    </div>
                    <div className={'character first-line'}>
                        <div className={'name'}>BINTI</div>
                        <div className={'icon'}><img src={BintiIcon} alt={""}/></div>
                        <div className={"description"}>Dynamique, inventive, imperturbable, mais un peu perturbante,
                            Binti est la pile énergique de la famille !
                            Des idées, elle en a des tonnes ! Et quoi de mieux qu’un petit frère pour les mettre en
                            action ?
                        </div>
                    </div>
                    <div className={'character first-line'}>
                        <div className={'name'}>OUMMI</div>
                        <div className={'icon'}><img src={OummiIcon} alt={""}/></div>
                        <div className={"description"}>Véritable pilier de la maison, Oummi s’occupe du foyer comme on
                            gère une start-up :
                            productivité, efficacité et surtout responsabilité !
                            Malgré sa rigueur, Oummi c’est surtout aussi la confidente de la maison...
                        </div>
                    </div>
                    <div className={'character first-line'}>
                        <div className={'name'}>ABI</div>
                        <div className={'icon'}><img src={AbiIcon} alt={""}/></div>
                        <div className={"description"}>Abi est ici, Abi est là, Abi est partout où on a besoin de lui !
                            Responsable d’une petite boutique, Abi n’est jamais avare lorsqu’il s’agit de partager de
                            précieux conseils. Et ça, Walad, en a besoin pour apprendre à grandir.
                        </div>
                    </div>

                    <div className={'character'}>
                        <div className={'name'}>RACHID</div>
                        <div className={'icon'}><img src={RachidIcon} alt={""}/></div>
                        <div className={"description"}>C’est le meilleur ami de Walad.
                            Discret, un peu maladroit, Rachid c’est surtout le gourmand de la bande !
                        </div>
                    </div>
                    <div className={'character'}>
                        <div className={'name'}>ANASS</div>
                        <div className={'icon'}><img src={AnassIcon} alt={""}/></div>
                        <div className={"description"}>À l’aise dans ses baskets, Anass n’est pas du genre à se laisser
                            faire. Mais Walad peut toujours compter sur lui en cas de problème !
                        </div>
                    </div>
                    <div className={'character'}>
                        <div className={'name'}>JÉRÔME</div>
                        <div className={'icon'}><img src={JeromeIcon} alt={""}/></div>
                        <div className={"description"}>Membre ou pas de la bande à Walad, on ne le sait pas vraiment.
                            C’est que Jérôme a la tête dure…très dure.
                        </div>
                    </div>
                    <div className={'character'}>
                        <div className={'name'}>MAX</div>
                        <div className={'icon'}><img src={MaxIcon} alt={""}/></div>
                        <div className={"description"}>Costaud, très costaud, Max sème la terreur partout où il passe.
                            Il parait qu’il a fait la paix, mais prudence...
                        </div>
                    </div>

                    <div className={'character'}>
                    </div>
                    <div className={'character'}>
                        <div className={'name'}>MAMIE BRICOLE</div>
                        <div className={'icon'}><img src={MamieIcon} alt={""}/></div>
                        <div className={"description"}>C’est la maman d’Abi. Elle vit seule en campagne où elle fabrique
                            toute sorte de gadgets pour le plus grand plaisir de Binti !
                        </div>
                    </div>
                    <div className={'character'}>
                        <div className={'name'}>M. STROGOFF</div>
                        <div className={'icon'}><img src={StrogoffIcon} alt={""}/></div>
                        <div className={"description"}>Inventeur surdoué, mais un peu fou, M. Strogoff est le voisin de
                            la Famille Foulane.
                        </div>
                    </div>
                    <div className={'character'}>
                    </div>
                </div>
            </div>
            <div className={"foulane--noredine"}>
                <div className={"text"}>
                    <div className={"title"}>Norédine Allam</div>
                    <div>
                        Née en 1977, à Amiens, en France, Norédine évolue dans le monde de la bande dessinée depuis plus
                        de 15 ans.
                        <br/>En 2009, il fonde les Editions du BDouin et lance la série Muslim Show.
                        <br/>Quelques années plus tard, désireux de s’adresser à un public plus jeune, il crée la série
                        La Famille Foulane.
                    </div>
                </div>
                <div className={"image"}><img src={NoredineImage} alt={""}/></div>
            </div>
            <div className={"foulane--footer"}>
                <div className={"upper-container"}>
                    <div className={""}>
                        <div className={"title"}>
                            <VerticalLogoFoulane/>
                        </div>
                        <div className={"description"}>
                            La Famille Foulane est une série créée par Norédine Allam et éditée par le BDouin.
                            <br/>Née en 2019, en France, la série raconte le quotidien d’une petite famille musulmane à
                            travers le personnage de Walad, le cadet.
                            <br/>Amusante, drôle, originale, la série navigue à travers des minis histoires où la morale
                            ne manque jamais.
                            <br/>Car après tout, les histoires, c’est fait pour grandir !
                        </div>
                    </div>
                    <div className={"links"}>
                        <div className={"link current-link"}><Link to={"/foulane/about"}>À propos</Link><br/><br/></div>
                        <div className={"link"}><Link to={"/foulane/novels"}>Les romans</Link><br/><br/></div>
                        <div className={"link"}><Link to={"/foulane/audios"}>Les livres audios</Link><br/><br/></div>
                        <div className={"link"}><Link to={"/foulane/games"}>Les jeux</Link><br/><br/></div>
                        <div className={"link"}><Link to={"/"}>Hoopow</Link><br/><br/></div>
                        <div className={"link"}><Link to={"/contactus"}>Contact</Link></div>
                    </div>
                    <div className={"download"}>
                        <div className={"text"}>Téléchargez notre application</div>
                        <div className={"icons"}>
                            <div className={"icon"}><PlayStoreIcon/></div>
                            <div className={"icon"}><AppStoreIcon/></div>
                        </div>
                    </div>
                </div>
                <div className={'line'}></div>
                <div className={'lower-container'}>
                    <div className={"copyright"}>©2022. Studio BDouin</div>
                    <div className={"legal-links"}>
                        <div>Conditions d'utilisation</div>
                        <div>Politique de confidentialité</div>
                        <div>Utilisation des cookies</div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default About;