import React, { useEffect } from 'react';

import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import {
  AuthState, onAuthUIStateChange,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
} from '@aws-amplify/ui-components';

import { Hub } from "aws-amplify";

import SignIn from './auth/SignIn';
import { ReactComponent as TopBanner } from '../assets/hoopow-topbanner.svg';
import { ReactComponent as HoopowLogo } from '../assets/hoopow-logo.svg';

import SignUp from './auth/SignUp';
import { Redirect, useHistory } from 'react-router-dom';

function Authentication(props: any) {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object>();
  const history = useHistory();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, [props]);


  useEffect(() => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: props.initialAuthState,
      data: null,
    });
  }, [props.initialAuthState])

  return (<>
    {(authState === AuthState.SignedIn && user) ? (
      <Redirect to="/" />
    ) : (<>
      <div className={`ks-content hp-homePage`}>
        <div className='hp-topBanner'>
          <TopBanner className='ks-clickable' onClick={() => history.push('/')} />
        </div>
        <div className='hp-topBanner-mobile'>
          <HoopowLogo className='ks-clickable' onClick={() => history.  push('/')} />
        </div>
        <AmplifyAuthenticator usernameAlias="email" initialAuthState={props.initialAuthState}>
          <SignIn />
          <SignUp {...props} />
        </AmplifyAuthenticator>
      </div>
    </>)
    }
  </>);
}

export default Authentication;
