import { useContext, useEffect, useState } from 'react';
import EidGiftCoupon from '../modal/EidGiftCoupon';
import EidGiftLayout from '../modal/EidGiftLayout';
import EidGiftV3 from '../modal/EidGiftV3';
import { UserContext } from '../utils/UserContext';
import ApplyCoupon from './ApplyCoupon';
import { ReactComponent as IconGift } from './assets/icon-gift.svg';
import './GiftSpaceEmbedded.scss';


function GiftSpace(props: any) {
  const urlParams = new URLSearchParams(props.location.search);
  const initialTab: number = (urlParams.get('tab') && parseInt(urlParams.get('tab') || "0")) || 0;
  const appId: string = urlParams.get('appId') || '';
  const [currentTab, setCurrentTab] = useState<number>(initialTab);
  
  const sessionId: any = urlParams.get('session_id');
  const { isUserAuthenticated } = useContext(UserContext);

  useEffect(() => {
    if (sessionId) {
      setCurrentTab(1);
    }
  }, [sessionId])

  const tabContent = (tab: number) => {
    switch (tab) {
      case 0:
        return <EidGiftV3 {...props} appId={appId}/>;
      case 1:
        return <EidGiftCoupon sessionId={sessionId} />;
      case 2:
        return <ApplyCoupon {...props} />;
      default:
        break;
    }
  }

  return (
    <div className="hp-giftspace">
      <EidGiftLayout headerComponent={<div className='hp-giftspace-header'><IconGift />code et carte cadeau</div>}>
        <div className='hp-giftspace-tabs'>
          {(sessionId || isUserAuthenticated) && (<>
            <div className={`hp-giftspace-tab-item ks-clickable ${currentTab === 0 ? 'selected' : ''}`} onClick={() => setCurrentTab(0)}>
              OFFRIR UNE CARTE CADEAU
            </div>
            <div className={`hp-giftspace-tab-item ks-clickable ${currentTab === 1 ? 'selected' : ''}`} onClick={() => setCurrentTab(1)}>
              ACCÈDER À MES CARTES
            </div>
            <div className={`hp-giftspace-tab-item ks-clickable ${currentTab === 2 ? 'selected' : ''}`} onClick={() => setCurrentTab(2)}>
              UTILISER UN CODE PROMO
            </div>
          </>)}
        </div>
        {tabContent(currentTab)}
      </EidGiftLayout>

    </div>
  );
}

export default GiftSpace;
