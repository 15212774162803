import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { allowedToEmbbedFrame, trim } from '../../utils/Constants';
import UserForm from "../elements/UserForm";

const EditUser = (props: any) => {
  const [initialUserData, setInitialUserData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const urlParams = new URLSearchParams(props.location?.search);
  const appId: any = urlParams.get('appId');

  useEffect(() => {
    setLoading(true);
    Auth.currentUserInfo().then((userInfo: any) => {
      setInitialUserData({
        name: userInfo.attributes.name,
        email: userInfo.attributes.email,
        avatarId: userInfo.attributes["custom:avatarId"]
      });
      setLoading(false);
    })
  }, [])

  const submit = async (formData: any, callback: Function) => {

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      if (formData.name !== initialUserData.name || formData.avatarId !== initialUserData.avatarId) {
        console.log(formData);
        await Auth.updateUserAttributes(currentUser, {
          'name': trim(formData.name),
          'custom:avatarId': formData.avatarId
        });
      }

      if (formData.password !== formData.oldPassword && formData.password !== '') {
        await Auth.changePassword(
          currentUser,
          formData.oldPassword,
          formData.password
        );
      }
      if (window.parent) {
        window.parent.postMessage({ 'step': 'edition_success' }, allowedToEmbbedFrame(appId));
      }
      callback();
    }
    catch (error: any) {
      let errorMessage = '';
      if (error.code && error.code === 'UsernameExistsException') {
        errorMessage = 'Cette adresse e-mail est déjà utilisée. N’hésitez pas à cliquer sur Mot de passe oublié en cas de besoin.';
      } else if (error.code && (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException')) {
        errorMessage = 'Votre mot de passe doit contenir au minimum 8 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.';
      } else {
        errorMessage = "Unknown error => " + error.message;
      }
      console.log('error Loading');
      callback(errorMessage);
    }
  }

  const isValidForm = (formData: any) => {
    return formData.name !== initialUserData.name ||
      formData.email !== initialUserData.email ||
      (formData.password !== '' && formData.oldPassword !== '') ||
      formData.avatarId !== initialUserData.avatarId
  }

  return (<>
    <div className="ks-embedded-authentication">
      <div className="ks-loginV3">
        {!loading && (
          <UserForm submit={submit} userData={initialUserData} isValidForm={isValidForm}
            changePasswordField showAvatar={props.showAvatar !== undefined ? props.showAvatar : true} />
        )}
      </div>
    </div>
  </>
  );
}
export default EditUser;