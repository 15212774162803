import { useContext, useEffect, useState } from 'react';
import './Feed.scss';
import ImageComponent from '../elements/ImageComponent';
import { ClipLoader } from 'react-spinners';
import FeedItem from './FeedItem';
import { MainConfigContext } from '../utils/MainConfigContext';


function Feed() {
  const { content, loadingFeed } = useContext(MainConfigContext);
  const [isPortrait, setIsPortrait] = useState<boolean>();
  const [contentOpen, setContentOpen] = useState<boolean>(false);
  const [selectedRubricIndex, setSelectedRubricIndex] = useState<number>(-1);
  const [currentArticleIndex, setCurrentArticleIndex] = useState<number>(0);

  const toggleContent = (selected: number) => {
    if (selectedRubricIndex === selected && contentOpen) setContentOpen(false);
    else { setSelectedRubricIndex(selected); setContentOpen(true); setCurrentArticleIndex(0); }
  }

  useEffect(() => {
    const resizeEvent: any = window.addEventListener("resize", function () {
      setIsPortrait(document.getElementById('app-platform')?.classList.contains('portrait'));
      return () => window.removeEventListener("resize", resizeEvent);
    }, false);
  }, [])

  const getFeedItemAttributeValue = (feedIndex: number, attributeName: string) => {
    return content && content[feedIndex] && content[feedIndex].feedItem[0]
      ? content[feedIndex].feedItem[0][attributeName] : '';
  }

  return (
    <>
      <div className={`hp-feed-frame`}>
        {loadingFeed ? (<div><ClipLoader color='white' /></div>) : (
          <>
            <div className={`hp-feed-primary ks-clickable`} onClick={() => toggleContent(0)}>
              <div className={`hp-feed-primary-title`}>{getFeedItemAttributeValue(0, 'rubricTitle')}</div>
              <ImageComponent imageKey={getFeedItemAttributeValue(0, 'imageHomepage')} alt='info2' />
              <div className={`hp-feed-primary-maintitle`}>{getFeedItemAttributeValue(0, 'name')}</div>
              <div className={`hp-feed-primary-text`}>{getFeedItemAttributeValue(0, 'shortFDesc')}</div>
              <button className='mainBtn'>LIRE</button>
            </div>
            <div className={`hp-feed-secondary ks-clickable ${process.env.REACT_APP_FEATURE_NEW_DESIGN_V3 === '1' && 'hp-v3'}`} onClick={() => toggleContent(1)}>
              <div className={`hp-feed-secondary-title`}>Késako ?</div>
              <ImageComponent imageKey={getFeedItemAttributeValue(1, 'imageHomepage')} alt='info2' />
              <button className='mainBtn'>DÉCOUVRIR</button>
            </div>
          </>
        )}

      </div >

      <div className={`hp-feed-frame-content ${contentOpen && 'open'}`}>
        <FeedItem key={'rubric' + selectedRubricIndex + '_' + currentArticleIndex}
          item={selectedRubricIndex !== -1 && content[selectedRubricIndex].feedItem[currentArticleIndex]}
          contentOpen={contentOpen}
          setContentOpen={setContentOpen} isPortrait={isPortrait || false}
          currentArticleIndex={currentArticleIndex} setCurrentArticleIndex={setCurrentArticleIndex}
          nbTotalArticle={selectedRubricIndex !== -1 && content[selectedRubricIndex].feedItem.length} />
      </div>
    </>
  );
}

export default Feed;
