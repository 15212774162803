import React from 'react';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import FileDS from '../../datastore/FileDS';

const AudioComponent = React.forwardRef((props: any, ref: any) => {
  const [fileURL, setFileURL] = useState('');
  const audioRef = useRef<any>();

  useEffect(() => {
    if (props.audioKey) {
      FileDS.getFileUrl(props.audioKey, (url: any, err: any) => {
        setFileURL(url);
      });
    } else {
      setFileURL('');
    }
  }, [props.audioKey]);

  useImperativeHandle(ref, () => ({
    play() {
      if (audioRef.current) {
        audioRef.current.play();
      }
    },
    stop() {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    },
  }), [audioRef]);

  return (
    <>
      {fileURL && (
        <>
          <audio ref={audioRef}
            src={fileURL}
            onEnded={props.onEnded}
            style={{ display: 'none', height: 0, width: 0 }}
          />
        </>
      )}
    </>
  );
});

export default AudioComponent;
