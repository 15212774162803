import { useEffect, useState } from "react";
import './PasswordReset.scss';
import { Auth } from 'aws-amplify';
import PasswordField from "./PasswordField";
import UserDS from "datastore/UserDS";

enum steps {
  INITIAL,
  CODE_SENT,
  PASSWORD_CHANGED
}

const textByLang: any = {
  "FR": {
    sendCode: {
      title: <>VOUS AVEZ OUBLIÉ VOTRE<br />MOT DE PASSE ?</>,
      description: <>Pour réinitialiser votre mot de passe,<br />saisissez l’adresse e-mail que vous utilisez pour vous connecter.</>,
      email: {
        label: "Adresse e-mail",
        placeholder: "Tapez ici votre email"
      },
      btnLabel: "ENVOYER"
    },
    changePassword: {
      title: <>E-mail envoyé !</>,
      description: <>Merci de consulter votre boîte email afin de récupérer votre code de vérification.</>,
      code: {
        label: "Code de vérification",
        placeholder: "Tapez ici votre code de vérification"
      },
      password: {
        label: "Nouveau mot de passe",
        placeholder: "Tapez ici votre nouveau mot de passe"
      },
      btnLabel: "VALIDER"
    },
    successMessage: <>Votre mot de passe a bien été modifié avec succès !<br />Veuillez vous connecter à l'application avec le nouveau mot de passe.</>,
    successBtnLabel: <>OK</>
  },
  "EN": {
    sendCode: {
      title: <>DID YOU FORGET YOUR<br />PASSWORD?</>,
      description: <>To reset your password, enter the email address<br />you use to log in.</>,
      email: {
        label: "Email address",
        placeholder: "Enter your email address"
      },
      btnLabel: "SEND"
    },
    changePassword: {
      title: <>E-mail sent !</>,
      description: <>Please check your email inbox to get your verification code.</>,
      code: {
        label: "Verification code",
        placeholder: "Enter your verification code"
      },
      password: {
        label: "New password",
        placeholder: "Enter your new password"
      },
      btnLabel: "SAVE"
    },
    successMessage: <>Your password has been changed successfully!<br />Please login to the application with the new password.</>,
    successBtnLabel: <>DONE</>
  }
}

//needed for lambda trigger hoopowMigration
Auth.configure({
  authenticationFlowType: 'USER_PASSWORD_AUTH',
})

const PasswordReset = (props: { location: any, successCallback: Function }) => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<steps>(steps.INITIAL);
  const urlParams = new URLSearchParams(props.location.search);
  const text: any = textByLang[(urlParams.get('lang') || "FR").toUpperCase()];

  const [formData, setFormData] = useState({
    email: '',
    verificationCode: '',
    newpassword: ''
  });
  const handleInputChange = (e: any, prop: string) => {
    setFormData({
      ...formData,
      [prop]: e.target.value
    });
  };

  const sendCodeClick = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    try {
      await UserDS.checkLegacyUser(formData.email);
      await Auth.forgotPassword(formData.email);
      setCurrentStep(steps.CODE_SENT);
    } catch (error) {
      console.error(error);

    }
    setLoading(false);
  }

  async function handleConfirmClick(event: any) {
    event.preventDefault();

    setLoading(true);

    try {
      await Auth.forgotPasswordSubmit(
        formData.email,
        formData.verificationCode,
        formData.newpassword
      );
      setCurrentStep(steps.PASSWORD_CHANGED);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    document.title = 'HOO POW | Login';
  }, []);

  return (
    <div className="ks-reset-password">
      {currentStep === steps.INITIAL && (<>
        <div className="ks-reset-password__title">{text.sendCode.title}</div>
        <div className="ks-reset-password__desc">{text.sendCode.description}</div>
        <form className='ks-form'>
          <div className="ks-form-group">
            <label className="ks-form-label">{text.sendCode.email.label}</label>
            <input type='email' placeholder={text.sendCode.email.placeholder} onChange={(e) => handleInputChange(e, 'email')}
              className="ks-form-control"
            />
          </div>
        </form>
        <div className="ks-formBtn">
          <button className="mainBtn" type="submit" onClick={(event) => { sendCodeClick(event) }} disabled={formData.email === '' || loading}>
            {text.sendCode.btnLabel}
          </button>
        </div>
      </>)}
      {currentStep === steps.CODE_SENT && (<>
        <div className="ks-reset-password__title">{text.changePassword.title}</div>
        <div className="ks-reset-password__desc">{text.changePassword.description}</div>
        <form className='ks-form' autoComplete="off">
          <div className="ks-form-group">
            <label className="ks-form-label">{text.changePassword.code.label}</label>
            <input type='number' autoComplete="off" placeholder={text.changePassword.code.placeholder} onChange={(e) => handleInputChange(e, 'verificationCode')}
              className="ks-form-control"
            />
          </div>
          <div className="ks-form-group">
            <label className="ks-form-label">{text.changePassword.password.label}</label>
            <PasswordField placeholder={text.changePassword.password.placeholder} autoComplete="new-password" onChange={(e: any) => handleInputChange(e, 'newpassword')} className="ks-form-control" />
          </div>
        </form>
        <div className="ks-formBtn">
          <button className="mainBtn" type="submit" onClick={(event) => { handleConfirmClick(event) }} disabled={formData.newpassword === '' || formData.verificationCode === '' || loading}>
            {text.changePassword.btnLabel}
          </button>
        </div>
      </>)}
      {currentStep === steps.PASSWORD_CHANGED && (<>
        <div className="ks-reset-password__title">{text.successMessage}</div>
        <div className="ks-formBtn">
          <button className="mainBtn" type="submit" onClick={() => props.successCallback()}>
            {text.successBtnLabel}
          </button>
        </div>
      </>)}
    </div>
  );
}
export default PasswordReset;