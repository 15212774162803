import Cookies from 'components/elements/common/Cookies';
import BlockedModal from 'components/elements/common/freemium/BlockedModal';
import { BlockerContext, steps } from 'components/utils/BlockerContext';
import { UserContext } from 'components/utils/UserContext';
import { useContext, useEffect } from 'react';
import MenuV3 from '../elements/common/freemium/MenuV3';
import SVG from '../elements/SVG';
import { MainConfigContext } from '../utils/MainConfigContext';
import Feed from './Feed';
import './LayoutV2.scss';

function LayoutV2(props: { children: any, className?: string, menuOpen?: boolean, isAuthenticated: boolean, location: any, onMobile?: string }) {

  const { mainConfig } = useContext(MainConfigContext);
  const { userBlocked, isUserAuthenticated } = useContext(UserContext);
  const { block, setBlock, unblockStep, setUnblockStep, nextStep } = useContext(BlockerContext);

  useEffect(() => {
    document.body.classList.remove('hp-body-homepage-notconnected-cgu');
    document.body.classList.remove('hp-body-homepage-notconnected');
  }, []);

  const openPopup = (screenType: steps) => {
    nextStep.current = steps.RELOAD;
    setUnblockStep(screenType);
    setBlock(true);
  }

  return (<>
    <div className={`ks-mainFrame ${props.className || ''} ${process.env.REACT_APP_FEATURE_NEW_DESIGN_V3 === '1' && 'hp-v3'} hp-layoutV2`}>
      <div id='hp-headerArea' className='hp-headerArea'>
        <MenuV3 menuOpen={props.menuOpen} setBlock={setBlock} />
      </div>
      <div id='hp-leftArea' className='hp-leftArea'>
        {mainConfig && (userBlocked || !isUserAuthenticated) && process.env.REACT_APP_FEATURE_PAUSE !== '1' && (
          <div className='hp-freemium-banner ks-clickable'>
            <SVG src={mainConfig.supportUs} className='hp-freemium-banner-bkp' onClick={() => openPopup(steps.SUPPORTUS)} />
          </div>
        )}
        <Feed />
      </div>
      <div id='mainContentScreen' className={'ks-content mobile ' + ("true" === props.onMobile ? "mobile" : "")}>
        {props.children}
        <BlockedModal open={block} setOpen={setBlock} unblockStep={unblockStep} setUnblockStep={setUnblockStep} nextStep={nextStep} onMobile={"true" === props.onMobile} />
      </div>
      <Cookies />
    </div>
  </>);
}

export default LayoutV2;
