import { useContext, useEffect, useState } from 'react';
import './css/HomepageNewDesignAuthenticated.scss';
import BaseDS from '../datastore/BaseDS';
import { TApplication, TAppNews, TMainConfig, TUserPlan } from '../types';
import AppCard from './elements/AppCard';
import SVG from './elements/SVG';
import { ReactComponent as Soon1 } from '../assets/v2/HOOPOW_IMAGE_01.svg';
import { ReactComponent as Soon2 } from '../assets/v2/HOOPOW_IMAGE_02.svg';
import { ReactComponent as Soon3 } from '../assets/v2/HOOPOW_IMAGE_03.svg';
import ImageComponentWithLoader from './elements/ImageComponentWithLoader';
import { ClipLoader } from 'react-spinners';
import UserDS from '../datastore/UserDS';
import { contractWithBanner } from '../utils/Constants'
import { Link } from 'react-router-dom';
import { UserContext } from './utils/UserContext';

function Homepage() {
  const [mainConfig, setMainConfig] = useState<TMainConfig>();
  const [appById, setAppById] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [userPlan, setUserPlan] = useState<TUserPlan>();
  const { isUserAuthenticated, userBlocked } = useContext(UserContext);

  useEffect(() => {
    document.title = 'HOO POW';
  }, []);

  useEffect(() => {
    if (isUserAuthenticated || process.env.REACT_APP_FEATURE_FREEMIUM === '1') {
      BaseDS.getMainDef().then((mainConfigTmp) => {
        setMainConfig(mainConfigTmp);
        if (mainConfigTmp.application) {
          const appByIdTmp: any = {};
          mainConfigTmp.application.forEach((appDefTmp: any, index: number) => {
            appByIdTmp[appDefTmp.id] = appDefTmp;
          });
          setAppById(appByIdTmp);
        }
      }).finally(() => setLoading(false));
    }
  }, [isUserAuthenticated]);

  const publishedApp = (applicationDef: TApplication) => {
    return applicationDef
      && applicationDef.publicationDate
      && parseInt(applicationDef.publicationDate.replace(/-/g, '')) <= parseInt(new Date().toISOString().replace(/-/g, ''))
  }

  const getFormattedDate = (isoDate: string): string => {
    if (isoDate && isoDate !== '') {
      const today = new Date().toISOString().split('T')[0];
      const endDate = new Date(isoDate);
      return today === isoDate ? "Aujourd'hui"
        : parseInt(isoDate.replace(/-/g, '')) === parseInt(today.replace(/-/g, '')) - 1
          ? 'hier' : 'Le  ' + endDate.toLocaleString("fr-FR", { day: '2-digit', month: 'long' });
    } else {
      return '';
    }
  }

  const getIconForApp = (appId: string) => {
    return appById && appById[appId] ? appById[appId].icon : undefined;
  }

  useEffect(() => {
    if (isUserAuthenticated) {
      UserDS.getUserPlan().then((plan: TUserPlan) => {
        setUserPlan(plan);
      })
    }
  }, [isUserAuthenticated]);

  const getDayBeforeExpiration = (endTimestamp: number) => {
    if (userPlan) {
      const endDate = new Date();
      endDate.setTime((endTimestamp * 1000));
      const today = new Date(new Date().toDateString());
      const diffInTime = endDate.getTime() - today.getTime();
      const result = Math.floor(diffInTime / (1000 * 3600 * 24));
      return result < 0 ? 0 : result;
    } else {
      return 0;
    }
  }

  const dayBeforeExpiration = userPlan ? getDayBeforeExpiration(userPlan.periodEnd) : -1;

  return (
    <div className='hp-mainContent hp-homepageV2'>
      <div className='hp-appNews'>
        <div className='hp-appNews-title' >
          Nouveautés
          {userPlan && contractWithBanner[userPlan.contractType] && (userPlan.contractType === 'free_plan' || dayBeforeExpiration < 7) && (
            <div className='hp-limitedPlan-duration' ><Link to='/member?showPlans=1'>I<span>l vous reste {dayBeforeExpiration} {dayBeforeExpiration < 2 ? 'JOUR' : 'JOURS'} {contractWithBanner[userPlan.contractType]}</span>&nbsp;-&nbsp;<b>CLIQUEZ-ICI POUR VOUS ABONNER</b></Link></div>
          )}
        </div>
        <div className='hp-appNews-frame' >
          {loading ? (<><ClipLoader /></>) : (<>
            {mainConfig && mainConfig.appNews && mainConfig.appNews.map((appNewTmp: TAppNews, index: number) => (
              <a id={'news_' + index} key={'news_' + index} className={'hp-appNews-line ' + (userBlocked ? 'ks-disabled' : '')} href={appNewTmp.urlToResource}>
                <ImageComponentWithLoader imageKey={appNewTmp.newsImage} />
                <span className='hp-appNews-line-date'>{getFormattedDate(appNewTmp.publicationDate)}</span>
                <div className='hp-appNews-line-frame'>
                  <SVG src={getIconForApp(appNewTmp.applicationId)} className='hp-appNews-line-icon' />
                  <div><div className='hp-appNews-line-title'>{appById[appNewTmp.applicationId].name}</div><div>{appNewTmp.name}</div></div>
                </div>
              </a>))}
          </>)}
        </div>
      </div>
      <div className='hp-appCards'>
        <div className='hp-appCards-title' >Applications</div>
        <div className='hp-appCards-frame'>
          {loading ? (<><ClipLoader /></>) : (<>
            {mainConfig && mainConfig.application && mainConfig.application.map((app: any, index: number) => (
              <AppCard key={'appCard_' + index} appDef={app}
                isPublished={publishedApp(app)}
                enabled={!userBlocked} />
            ))}
          </>)}
        </div>
      </div>
      <div className='hp-appSoon'>
        <div className='hp-appSoon-title' >HOOPOW</div>
        <div className='hp-appSoon-frame'>
          {loading ? (<><ClipLoader /></>) : (<>
            <Soon1 className='ks-clickable' onClick={() => {
              if (mainConfig && mainConfig.application) {
                document.location.href = mainConfig.application
                  .filter(app => app.name.toLowerCase().indexOf('différence') > -1)[0].url;
              }
            }} />
            <Soon2 className='ks-clickable' onClick={() => {
              if (mainConfig && mainConfig.application) {
                document.location.href = mainConfig.application.filter(app => app.name.toLowerCase().indexOf('awlad') > -1)[0].url;
              }
            }} />
            <Soon3 className='ks-clickable' onClick={() => {
              if (mainConfig && mainConfig.application)
                document.location.href = mainConfig.application.filter(app => app.name.toLowerCase().indexOf('foulane') > -1)[0].url + '/walad';
            }} />
          </>)}
        </div>
      </div>
    </div>
  );
}

export default Homepage;
