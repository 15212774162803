import './Subscribe.scss';
import { ReactComponent as HoopowLogo } from '../../assets/hoopow-logo.svg';
import { ReactComponent as CloseBtn } from '../../assets/close-btn.svg';
import { useHistory } from 'react-router-dom';
import Plans from '../elements/Plans';
import FAQ from '../elements/FAQ';
import 'react-toastify/dist/ReactToastify.css';

function Subscribe() {
  const history = useHistory();

  return (
    <div className="hp-subscribe">
      <CloseBtn className='hp-closeBtn ks-clickable' onClick={() => history.push('/')} />
      <HoopowLogo className="hp-subscribe-logo" />
      <div className='hp-subscribe-plans-faq'>
        <Plans />
        <FAQ />
      </div>
    </div>
  );
}

export default Subscribe;
