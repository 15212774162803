import './CheckoutForm.scss'
import './CheckoutFormMobile.scss'
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import Stripe, {PaymentMethodResult, StripeCardNumberElementOptions} from "@stripe/stripe-js";
import BaseDS from "../../datastore/BaseDS";
import {ClipLoader} from 'react-spinners';
import {ReactComponent as EmailIcon} from '../../assets/v3/email-icon.svg';
import {ReactComponent as CBIcon} from '../../assets/v3/cb-icon.svg';
import {ReactComponent as CVCIcon} from '../../assets/v3/cvc-icon.svg';
import {ReactComponent as CalendarIcon} from '../../assets/v3/calendar-icon.svg';
import {ReactComponent as WarningIcon} from '../../assets/v3/warning-icon.svg';
import {ReactComponent as StripeLogo} from '../../assets/v3/stripe-logo.svg';
import {ReactComponent as SupportUsText} from '../../assets/v3/support-text.svg';
import {ReactComponent as DonationThanks} from '../../assets/v3/donation-thanks.svg';
import {ReactComponent as CBIcons} from '../../assets/v3/cb-icons.svg';
import {ReactComponent as VISAIcon} from '../../assets/v3/VISA-icon.svg';
import {ReactComponent as MasterCardIcon} from '../../assets/v3/MasterCard-icon.svg';
import {ReactComponent as DonationArrow} from '../../assets/v3/donation-arrow.svg';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import useResponsiveSize from '../utils/useResponsiveSize';
import SuccessfulPayment from './SuccessfulPayment';
import {UserContext} from '../utils/UserContext';
import {allowedToEmbbedFrame} from '../../utils/Constants';
import CheckoutHeaderTemplate from '../embedded/components/CheckoutHeaderTemplate';
import {TPaymentInfo} from '../../types';
import SVG from './SVG';

const pricingTypeToText: any = {
    recurring: "ABONNEMENT DE",
    one_time: "PAIEMENT DE"
}

const purchaseTemplateById: any = {
    "muslimshow_oneshot_full": {
        imgKey: "images/purchase-mshow.svg",
        title: "Bande dessinée",
        description: "Tous les jours, une histoire en format BD.",
        content: "50 épisodes",
        status: "En cours"
    },
    "yearly_plan_v3": {
        imgKey: "images/purchase-hoopow.svg",
        title: "Abonnement HOOPOW",
        description: "Accès à l’entièreté de la plateforme.",
        content: "La plateforme",
        status: "En cours"
    },
    "monthly_plan_v3": {
        imgKey: "images/purchase-hoopow.svg",
        title: "Abonnement HOOPOW",
        description: "Accès à l’entièreté de la plateforme.",
        content: "La plateforme",
        status: "En cours"
    },
    "half_year_gift_plan": {
        imgKey: "images/purchase-gift.svg",
        title: "Carte cadeau",
        description: "Abonnement de 6 mois.",
        content: "La plateforme",
        quantity: "%d"
    }
}

const useOptions = () => {
    const fontSize = useResponsiveSize(20);
    const options: StripeCardNumberElementOptions = useMemo<StripeCardNumberElementOptions>(() => ({
        style: {
            base: {
                fontSize: fontSize + 'px',
                color: "black",
                fontFamily: "Avenir",
                fontWeight: "400",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#BF594C"
            }
        }
    }), [fontSize]);

    return options;
};

const CheckoutForm = (props: { productId: string, itemId: string, location: any, onMobile: boolean }) => {

    const stripe = useStripe();
    const elements = useElements();
    const elementsRef: any = useRef({});
    const [email, setEmail] = useState<string>('');
    const [pricingText, setPricingText] = useState<string>();
    const [price, setPrice] = useState<string>();

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isSuccessPayment, setIsSuccessPayment] = useState<boolean>(false);
    const {itemId, onMobile} = props;
    const options: StripeCardNumberElementOptions = useOptions();
    const {userInfo} = useContext(UserContext);
    const urlParams = new URLSearchParams(props.location.search);
    const appId: any = urlParams.get('appId');
    const quantity: any = (urlParams.get('quantity') && parseInt(urlParams.get('quantity') || "1")) || 1;
    const urlCallback: string | null = urlParams.get('urlCallback');
    const [productId, setProductId] = useState<string>(props.productId);
    const paymentInfo: TPaymentInfo = purchaseTemplateById[productId] || {
        image: window.frameElement?.getAttribute('data-image') || "no-data",
        title: window.frameElement?.getAttribute('data-title') || "no-data",
        description: window.frameElement?.getAttribute('data-description') || "no-data",
        content: window.frameElement?.getAttribute('data-content') || "no-data",
        status: window.frameElement?.getAttribute('data-status') || undefined,
        quantity: window.frameElement?.getAttribute('data-quantity') || undefined,
        url: window.frameElement?.getAttribute('data-url'),
    };

    const loaderSize = useResponsiveSize(30);
    const loaderPaymentSize = useResponsiveSize(150);

    useEffect(() => {
        if (productId.indexOf('donation') === -1) {
            BaseDS.getProductDetailsById(productId).then((productDetails) => {
                setPricingText(pricingTypeToText[productDetails.type]);
                setPrice((productDetails.unit_amount * quantity) / 100 + "€");
            });
        }
    }, [productId, quantity])

    useEffect(() => {
        setEmail(userInfo?.attributes && userInfo.attributes.email);
    }, [userInfo])

    const handleInputChange = (e: any) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        displayError('');
        setLoading(true);

        if (!stripe || !elements || !elements.getElement(CardNumberElement)) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        const cardNumberElementObject: any = elements.getElement(CardNumberElement);

        const paymentMethod: PaymentMethodResult = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElementObject
        });
        if (paymentMethod.paymentMethod) {
            try {
                const paymentIntent = await BaseDS.createPurchase(productId, itemId, email, paymentMethod.paymentMethod?.id, quantity, paymentInfo);
                await handlePaymentThatRequiresCustomerAction(paymentIntent, paymentMethod.paymentMethod?.id);
            } catch (error: any) {
                displayError({error: {message: "Erreur durant la transaction."}});
                setLoading(false);
            }
        } else {
            displayError(paymentMethod);
            setLoading(false);
        }

    };
    const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
    useEffect(() => {
        setIsSubmittable(!!stripe && !!elements && !!elements.getElement(CardNumberElement) && email?.length > 0
            //TODO: Make sure bank info is not empty
            //        && (elementsRef.current["cardNumber"]?.value?.length > 0)
            //        && (elementsRef.current["cardExpiry"]?.value?.length > 0)
            //        && (elementsRef.current["cardCvc"]?.value?.length > 0)
        )
    }, [stripe,
        elements,
        email]);

    const hasError = (cardElement: any) => {
        if (cardElement.error) {
            elementsRef.current[cardElement.elementType].classList.add("invalid");
        } else {
            elementsRef.current[cardElement.elementType].classList.remove("invalid");
        }
    }

    function displayError(event: any) {
        if (event.error) {
            setErrorMessage(event.error.message);
        } else {
            setErrorMessage('');
        }
    }

    const handlePaymentThatRequiresCustomerAction = async (paymentIntent: Stripe.PaymentIntent, paymentMethodId: string) => {
        if (
            paymentIntent.status === 'requires_action' ||
            paymentIntent.status === 'requires_payment_method'
        ) {
            return stripe?.confirmCardPayment(paymentIntent.client_secret || 'no_client_secret', {
                payment_method: paymentMethodId,
            })
                .then(async (result) => {
                    if (result.error) {
                        alert(result.error)
                        throw result;
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            await BaseDS.verifyPurchase(productId, itemId);
                            setIsSuccessPayment(true);
                        }
                    }
                })
                .catch((error) => {
                    displayError(error);
                }).finally(() => setLoading(false));
        } else {
            await BaseDS.verifyPurchase(productId, itemId);
            setLoading(false);
            setIsSuccessPayment(true);
        }
    }

    return (
        <div className={"hp-checkout " + (onMobile ? "mobile-only" : "desktop-only")}>
            {paymentInfo !== undefined ? (<>
                {productId === "monthly_plan_v3" ?
                    (<div className="hp-checkout-subscription">
                        <SVG src="images/purchase-hoopow.svg"/>
                        <div className="hp-checkout-subscription-text">
                            <div className="hp-checkout-subscription-top">
                                En souscrivant à un abonnement mensuel, vous aurez accès à l’ensemble du contenu de la
                                plateforme.
                            </div>
                            <div className="hp-checkout-subscription-bottom">
                                Le renouvellement est automatique, mais vous pourrez le stopper gratuitement quand vous
                                le
                                souhaitez !
                            </div>
                        </div>
                    </div>) :
                    (<>
                        {productId.indexOf('donation') > -1 ? (<>
                            <div className="hp-checkout-donation">
                                <SVG src="images/donation-awlad.svg"/>
                                <div className="hp-checkout-donation-text">
                                    <div className="hp-checkout-donation-top">
                                        Le logiciel Awlad School est 100% GRATUIT ! Participez à l’action !
                                    </div>
                                    <div className="hp-checkout-donation-bottom">
                                        Avec votre donation, vous participez à la promotion de la langue arabe<br/>
                                        dans la communauté et vous soutenez la production de nouvelles leçons.<br/>
                                        <b>Facilitons à tous l’accès aux connaissances bénéfiques !</b><br/>
                                    </div>
                                </div>
                            </div>
                        </>) : (
                            <>
                                {paymentInfo?.title && (
                                    <CheckoutHeaderTemplate paymentInfo={paymentInfo} quantity={quantity}
                                                            onMobile={onMobile}/>
                                )}
                            </>
                        )}
                    </>)}
                <div className="hp-checkoutWrapper">
                    <div className="hp-checkoutPricing">
                        {productId.indexOf('donation') > -1 ? (
                                <div className='hp-checkoutPricing-donation'>
                                    <div>CHOISISSEZ LE MONTANT DE VOTRE DONATION</div>
                                    <ul>
                                        <li className={"" + (productId === 'donation_10' && 'selected')}
                                            onClick={() => setProductId('donation_10')}><DonationArrow/><b>10</b>€
                                        </li>
                                        <li className={"" + (productId === 'donation_20' && 'selected')}
                                            onClick={() => setProductId('donation_20')}><DonationArrow/><b>20</b>€
                                        </li>
                                        <li className={"" + (productId === 'donation_50' && 'selected')}
                                            onClick={() => setProductId('donation_50')}><DonationArrow/><b>50</b>€
                                        </li>
                                        <li className={"" + (productId === 'donation_100' && 'selected')}
                                            onClick={() => setProductId('donation_100')}><DonationArrow/><b>100</b>€
                                        </li>
                                        <li className={"" + (productId === 'donation_1000' && 'selected')}
                                            onClick={() => setProductId('donation_1000')}><DonationArrow/><b>1000</b>€
                                        </li>
                                    </ul>
                                </div>) :
                            onMobile ? (<><MasterCardIcon/>
                                    <div>{pricingText}&nbsp;<b>{price}</b>&nbsp;</div>
                                    <VISAIcon/></>)
                                : (<>{pricingText}&nbsp;<b>{price}</b>&nbsp;<CBIcons/></>)}
                    </div>
                    <div className={"card-element-errors " + (!errorMessage ? "empty" : "")}
                         role="alert">{errorMessage}</div>
                    {!isSuccessPayment ? (
                        <>
                            <form onSubmit={handleSubmit} className='hp-checkoutForm'>
                                <label>
                                    <span>{onMobile ? "EMAIL" : "E-mail"}</span>
                                    <div className='field'>
                                        <EmailIcon className="fieldIcon email"/>
                                        <input type='email' placeholder="Tapez ici votre email" className="inputForm"
                                               value={email || ''} onChange={handleInputChange}/>
                                    </div>
                                </label>
                                <label>
                                    <span>{onMobile ? "NUMÉRO DE CARTE" : "Numéro de carte"}</span>
                                    <div className='field' ref={ref => elementsRef.current["cardNumber"] = ref}>
                                        <CBIcon className="fieldIcon cb"/>
                                        <CardNumberElement options={options} className="inputForm" onChange={hasError}/>
                                        <WarningIcon className="warning-icon"/>
                                    </div>
                                </label>
                                <div className='grouped-field'>
                                    <label className='left'>
                                        <span>{onMobile ? "EXPIRE LE" : "Date d’expiration"}</span>
                                        <div className='field' ref={ref => elementsRef.current["cardExpiry"] = ref}>
                                            <CalendarIcon className="fieldIcon calendar"/>
                                            <CardExpiryElement options={options} className="inputForm"
                                                               onChange={hasError}/>
                                            <WarningIcon className="warning-icon"/>
                                        </div>
                                    </label>
                                    <label className='right'>
                                        <span>{onMobile ? "NUMÉRO DE SÉCURITÉ" : "Numéro de sécurité"}</span>
                                        <div className='field' ref={ref => elementsRef.current["cardCvc"] = ref}>
                                            <CVCIcon className="fieldIcon cvc"/>
                                            <CardCvcElement options={options} className="inputForm"
                                                            onChange={hasError}/>
                                            <WarningIcon className="warning-icon"/>
                                        </div>
                                    </label>
                                </div>
                                <button type="submit" disabled={!stripe}
                                        className={`${loading || !isSubmittable && 'disabled'}`}>
                                    {loading ? (<ClipLoader size={loaderSize} color="white"/>) : (<>PAYER</>)}
                                </button>
                                <div className='stripe-logo'>Paiement sécurisé par&nbsp;<StripeLogo/></div>
                            </form>
                            {productId === "monthly_plan_v3" ? (
                                <div className='support-us-text'><SupportUsText/></div>
                            ) : (<>
                                {productId.indexOf('donation') > -1 ? (
                                    <div className='donation-thanks-text'><DonationThanks/></div>
                                ) : (<></>)}
                            </>)}
                        </>) : (<SuccessfulPayment productId={productId} onMobile={onMobile} action={() => {
                        if (urlCallback) {
                            document.location.href = urlCallback;
                        } else {
                            if (window.parent) {
                                window.parent.postMessage({'step': 'payment_done_successfully'}, allowedToEmbbedFrame(appId));
                            }
                        }
                    }}/>)}
                </div>
            </>) : (<div className="loaderCheckout"><ClipLoader size={loaderPaymentSize}/></div>)}
        </div>);
};

export default CheckoutForm;
