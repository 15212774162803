import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import * as serviceWorker from './serviceWorker';
import GA4React from 'ga-4-react';

Amplify.configure(awsconfig);


// if (document.referrer !== '' 
// && document.referrer.indexOf('proto-hoopow.com') < 0 
// && document.referrer.indexOf('stripe.com') < 0 
// && process.env.REACT_APP_ENVIRONMENT_APP !== 'prod'
// && process.env.REACT_APP_ENVIRONMENT_APP !== 'dev') {
//   document.location.href = 'https://www.hoo-pow.com'
// }

(async () => {
  if (process.env.REACT_APP_GA_ID && process.env.REACT_APP_GA_ID !== '') {
    console.log('Initialise google analytics with the following code => ' + process.env.REACT_APP_GA_ID);
    const ga4react = new GA4React(process.env.REACT_APP_GA_ID);
    try {
      await ga4react.initialize();
    } catch (err) {
      console.warn(err);
    }
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("app-platform")
  );
})();

serviceWorker.unregister();

