import UserContextProvider from '../utils/UserContext';
import './EmbeddedLayout.scss';

function EmbeddedLayout(props: { children: any, className?: string, menuOpen?: boolean, onMobile: boolean }) {

  return (<>
    <UserContextProvider>
      <div id="embedded-frame" className={`ks-embedded hp-v3  hp-layoutV2 ${props.className}` + (props.onMobile ? " mobile-only" : " desktop-only")}>
        <div className={'ks-content ' + (props.onMobile ? "mobile-only" : "desktop-only")}>
          {props.children}
        </div>
      </div>
    </UserContextProvider>
  </>);
}

export default EmbeddedLayout;
