import { useEffect, useState } from 'react';
import FileDS from '../../datastore/FileDS';
import { ClipLoader } from 'react-spinners';

const ImageComponent = (props: {
  children: any;
  imageKey: any;
  onClick?: any;
  alt: string | undefined;
  width: string | number | undefined;
  className: string | undefined;
}) => {
  const [fileURL, setFileURL] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const { children } = props;

  useEffect(() => {
    if (props.imageKey) {
      FileDS.getFileUrl(props.imageKey, (url: any, err: any) => {
        setFileURL(url);
      });
    } else {
      setFileURL('');
    }
  }, [props.imageKey]);

  return (
    <>
      {fileURL && (
        <>
          <img
            src={fileURL}
            alt={props.alt}
            width={props.width}
            onClick={props.onClick}
            className={props?.className}
            style={{ display: loading ? 'none' : 'block' }}
            onLoad={() => setLoading(false)}
          />
          <div className='hp-imageLoader' style={{ display: loading ? 'flex' : 'none' }}>
            <ClipLoader />
          </div>
        </>
      )}
      {!fileURL && <>{children}</>}
    </>
  );
};

export default function Image(props: any) {
  return <ImageComponent {...props} />;
}
