import './ApplyCoupon.scss';
import Lottie from 'react-lottie-player'
import lottieJson from './assets/lock.json'
import { useEffect, useState } from 'react';
import useResponsiveSize from '../utils/useResponsiveSize';
import BaseDS from '../../datastore/BaseDS';
import { TUserPlan } from '../../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDayBeforeExpiration } from '../../utils/Constants';

function ApplyCoupon() {
  const [play, setPlay] = useState<boolean>(false);
  const [couponId, setCouponId] = useState<string>('');
  const [couponStatus, setCouponStatus] = useState<string>('none');
  const [applyingCoupon, setApplyingCoupon] = useState<boolean>(false);
  const [userPlan, setUserPlan] = useState<TUserPlan>();
  const lottieSize = useResponsiveSize(300);

  useEffect(() => {
    // Configuring the notification 
    toast.configure();
  }, []);

  const notifySuccess = (userData: any) => {
    console.log("userData.periodEnd", userData.periodEnd);
    toast.success(`Votre coupon a bien été pris en compte et votre compte expire dans ${getDayBeforeExpiration(userData.periodEnd, userPlan)} ${getDayBeforeExpiration(userData.periodEnd, userPlan) < 2 ? 'jour' : 'jours'}`, { position: toast.POSITION.TOP_CENTER });
  }
  const notifyFail = (message?: string) => {
    const errMessage = message || `Le coupon ${couponId} a déjà été utilisé ou n'existe pas.`;
    toast.error(errMessage, { position: toast.POSITION.TOP_CENTER });
  }

  return (
    <div className="hp-giftspace-apply-coupon">

      <div className="hp-giftspace-apply-coupon-frame">
        <Lottie audioFactory={""} animationData={lottieJson} play={play} loop={false} style={{ width: lottieSize, height: lottieSize }} />
        <div className="hp-giftspace-apply-coupon-field">
          <span>Merci d’entrer votre code</span>
          <input maxLength={8} onChange={(event: any) => setCouponId(event.target.value)} />
          <button className='mainBtn' disabled={couponId.length < 8 || applyingCoupon} onClick={() => {
            setApplyingCoupon(true);
            setCouponStatus('En cours');
            BaseDS.applyCoupon(couponId).then((userData: any) => {
              setCouponStatus('OK')
              setUserPlan(userData);
              notifySuccess(userData);
              setPlay(true);
            }).catch((err) => {
              setCouponStatus('Erreur')
              console.log("err.response", err.response?.data);
              if (err.response && err.response.data && err.response.data.message && err.response.data.message.indexOf("Unknow coupon") > -1) {
                notifyFail("Désolé, le code que vous avez entré n’existe pas.");
              } else if (err.response && err.response.data && err.response.data.message 
                && (err.response.data.message.indexOf("Coupon already used") > -1 || err.response.data.message.indexOf("Coupon reach limit") > -1)) {
                notifyFail("Désolé, le code que vous avez entré a déjà été utilisé.")
              } else {
                notifyFail("Désolé, un problème est survenu lors de l'opération.")
              }
              setPlay(false);
            });
          }} >{couponStatus === 'none' ? 'VALIDER' : couponStatus}</button>
        </div>
      </div>
    </div>
  );
}

export default ApplyCoupon;
