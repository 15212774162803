import "./CheckoutHeaderTemplate.scss"
import "./CheckoutHeaderTemplateMobile.scss"
import {ReactSVG} from "react-svg";
import {TPaymentInfo} from "../../../types";
import FileDS from "../../../datastore/FileDS";

function CheckoutHeaderTemplate(props: { paymentInfo: TPaymentInfo, quantity?: number, onMobile?: boolean }) {
    const {paymentInfo, quantity, onMobile} = props;
    return (
        <>
            <div className={"hp-blockedModal-popup__header " + (onMobile ? "mobile-only" : "desktop-only")}>
                <ReactSVG
                    src={(paymentInfo?.imgKey && FileDS.getUrl(paymentInfo?.imgKey)) || (paymentInfo?.image || "")}
                    className="hp-blockedModal-popup__header-icon"/>
                <div className="hp-blockedModal-popup__header-text">
                    <div className="hp-blockedModal-popup__header-title">
                        <b>{paymentInfo?.title}</b>
                    </div>
                    <div className="hp-blockedModal-popup__header-desc">
                        {paymentInfo?.description}
                    </div>
                    <div className="hp-blockedModal-popup__header-content">
                        <b>Contenu : </b>{paymentInfo?.content}
                    </div>
                    {paymentInfo?.quantity && quantity ? (<>
                        <div className="hp-blockedModal-popup__header-state">
                            <b>Quantité : </b>{paymentInfo?.quantity.replace('%d', (quantity || 1).toString())}
                        </div>
                    </>) : (<>
                        {!onMobile && paymentInfo?.status && (
                            <div className="hp-blockedModal-popup__header-state">
                                <b>État : </b>{paymentInfo?.status}
                            </div>
                        )}
                    </>)}
                </div>
            </div>
        </>
    );
}

export default CheckoutHeaderTemplate;
