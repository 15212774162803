import './SuccessfulPayment.scss';
import {ReactComponent as SuccessIcon} from '../../assets/v3/payment_success.svg';
import {ReactComponent as StripeLogo} from '../../assets/v3/stripe-logo.svg';


type Props = { action: Function, productId: string, onMobile?: boolean }
const SuccessfulPayment = (props: Props) => {

    return (
        <div className={"hp-successfulPayment " + (props.onMobile ? "mobile-only" : "desktop-only")}>
            {/*TODO: add checkmark animated Lottie */}
            {props.onMobile ?
                <SuccessIcon/>
                : <SuccessIcon/>}
            {props.productId.indexOf('donation_') > -1 ? (<>
                <div className="hp-successfulPayment__info">VOTRE DONATION A BIEN ÉTÉ VALIDÉ !</div>
                <div className="hp-successfulPayment__thanks">
                    Nous vous remercions pour votre soutien et nous espérons vous compter encore longtemps parmi nos
                    mécènes incha Allah !
                </div>
            </>) : (<>
                <div className="hp-successfulPayment__info">VOTRE PAIEMENT A ÉTÉ VALIDÉ !</div>
                <button className="hp-successfulPayment__btn" onClick={() => props.action()}>CONSULTER LE CONTENU
                </button>
                <div className="hp-successfulPayment__thanks">Merci ! En achetant du contenu vous financez le
                    développement de la plateforme.
                </div>
            </>)}
            <div className='stripe'>Paiement sécurisé par&nbsp;<StripeLogo/></div>
        </div>
    );
};

export default SuccessfulPayment;
