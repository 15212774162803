import "./Slider.scss";

import {
  Fragment,
  forwardRef,
  useState,
} from "react";
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";

import useWindowResize from "../../../utils/useWindowResize";

const Slider = forwardRef(
  (
    props: {
      length: number;
      children: any;
      setCurrent: any
    },
    ref: any
  ) => {
    const [movement, setMovement] = useState<number>(0);
    const [transitionDuration, setTransitionDuration] = useState<number | string>(0);
    const [lastTouch, setLastTouch] = useState<number>(0);
    const [percentageSlided, setPercentageSlided] = useState<number>(0);
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
    let transitionTimeout: any;
    const { mobileSize } = useWindowResize();
    const mvtStep = mobileSize ? 25 : 100;


    const handleMovement = (delta: number) => {
      clearTimeout(transitionTimeout);
      const maxLength = props.length;
      setPercentageSlided((delta / ref.current.clientWidth) * mvtStep);
      const baseSlidePercentage = mvtStep * currentSlideIndex;
      let nextMovement = mvtStep * (delta / ref.current.clientWidth) + baseSlidePercentage; // 500px / 2200px 0.25 => 25%
      if (nextMovement > maxLength * mvtStep) {
        nextMovement = maxLength * mvtStep;
      } else if (nextMovement <= 0) {
        nextMovement = 0;
      }
      setMovement(nextMovement);
      setTransitionDuration("Os");
    };

    const handleTouchStart = (e: any) => {
      setPercentageSlided(0);
      setLastTouch(e.nativeEvent.touches[0].clientX);
    };

    const handleTouchMove = (e: any) => {
      const delta =
        lastTouch - e.nativeEvent.touches[0].clientX;
      handleMovement(delta);
    };

    const handleTouchEnd = () => {
      if (Math.abs(percentageSlided) >= 1) {
        if (percentageSlided > 0 && currentSlideIndex + 1 <= props.length - 1) {
          transitionTo(currentSlideIndex + 1, 0.5)
        } else if (percentageSlided < 0 && currentSlideIndex - 1 >= 0) {
          transitionTo(currentSlideIndex - 1, 0.5)
        } else {
          transitionTo(currentSlideIndex, 0.5)
        }
      }
      setLastTouch(0);
    };

    const transitionTo = (
      index: number,
      duration: number
    ) => {
      props.setCurrent(index);
      setCurrentSlideIndex(index);
      setMovement(index * mvtStep);
      setTransitionDuration(`${duration}s`);
      transitionTimeout = setTimeout(() => {
        setTransitionDuration("0s");
      }, duration * mvtStep);
    };

    return (
      <Fragment>
        {!mobileSize &&
          (currentSlideIndex === 0 ? (
            ""
          ) : (
            <IoIosArrowBack
              className="main-slider__arrow main-slider__arrow--prev"
              onClick={() => {
                transitionTo(currentSlideIndex - 1, 0.5);
              }}
            />
          ))}

        <div
          onDragStart={(e) => e.preventDefault()}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          ref={ref}
          className="main-slider"
        >
          <div
            style={{
              transform: `translateX(${movement * -1}%) `,
              transitionDuration: `${transitionDuration}`,
            }}
            className="main-slider__swiper"
          >
            {props.children}
          </div>
        </div>
        {!mobileSize &&
          (currentSlideIndex === props.length - 1 ? (
            ""
          ) : (
            <IoIosArrowForward
              className="main-slider__arrow main-slider__arrow--next"
              onClick={() => {
                transitionTo(currentSlideIndex + 1, 0.5);
              }}
            />
          ))}
      </Fragment>
    );
  }
);

export default Slider;
