import './css/HomepageUnauthentifiedNew.scss'
import { ReactComponent as HoopowLogo } from '../assets/hoopow-logo.svg';
import { ReactComponent as HomepageBG } from '../assets/homepage_notconnected.svg';
import { ReactComponent as HomepageMobileBG } from '../assets/homepage_notconnected_mobile.svg';
import { ReactComponent as BtnEidGift } from '../assets/gifts/btn-eidaladha-gift.svg';
import { ReactComponent as BtnEidGiftMobile } from '../assets/gifts/btn-eidaladha-gift-mobile.svg';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import FAQs from './elements/FAQ';
import SignUpForm from './elements/SignupForm';
import { Link, useHistory } from 'react-router-dom';
import EidGift from './modal/EidGift';
import EidGiftCoupon from './modal/EidGiftCoupon';
import EidGiftLayout from './modal/EidGiftLayout';

function Homepage(props: any) {
  const history = useHistory();

  const hoopowLogoDiv = useRef<any>();
  const signupDiv = useRef<any>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(props.openModal);
  const modalElement: HTMLElement = document.body;
  const urlParams = new URLSearchParams(props.location.search);
  const sessionId: any = urlParams.get('session_id');
  const couponId: any = urlParams.get('coupon_id');
  const [couponModalIsOpen, setCouponModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setCouponModalIsOpen(sessionId || couponId ? true : false);
  }, [sessionId, couponId])

  useEffect(() => {
    setModalIsOpen(props.openModal);
  }, [props.openModal])

  useEffect(() => {
    if (modalIsOpen || couponModalIsOpen) modalElement.classList.add('nooverflow');
    else modalElement.classList.remove('nooverflow');
  }, [modalIsOpen, couponModalIsOpen, modalElement])

  useEffect(() => {
    document.body.classList.remove('hp-body-homepage-notconnected-cgu');
    document.body.classList.add('hp-body-homepage-notconnected');
  }, []);

  const scrollToSubscribe = () => {
    if (window.innerWidth > 748) {
      hoopowLogoDiv.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      signupDiv.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  return (
    <>
      {couponModalIsOpen && (
        <Modal
          isOpen={couponModalIsOpen}
          onRequestClose={() => setCouponModalIsOpen(false)}
          portalClassName={'ReactModalPortal hp-eidgift-modal'}
          contentLabel="Cadeau du Aïd"
          parentSelector={() => modalElement}
          appElement={modalElement}
        >
          <EidGiftLayout closeScreen={setCouponModalIsOpen} >
            <EidGiftCoupon sessionId={sessionId} couponId={couponId} />
          </EidGiftLayout>
        </Modal>
      )}

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          portalClassName={'ReactModalPortal hp-eidgift-modal'}
          contentLabel="Cadeau du Aïd"
          parentSelector={() => modalElement}
          appElement={modalElement}
        >
          <EidGiftLayout closeScreen={setModalIsOpen} >
            <EidGift />
          </EidGiftLayout>
        </Modal>
      )}
      {process.env.REACT_APP_ENABLE_AD === '1' && (
        <BtnEidGiftMobile className='hp-hoopow-homepage-eid-gift-mobile ks-clickable' onClick={() => setModalIsOpen(true)} />
      )}
      <div className={`hp-homepage-notconnected ${process.env.REACT_APP_ENABLE_AD !== '1' && ' noAdHeader'}`}>
        <div className='ks-homepage-notconnected-topBtn'>
          <button className='mainBtn' onClick={scrollToSubscribe}>S’INSCRIRE</button>
          <button className='mainBtn hp-homepage-connectionBtn' onClick={() => history.push('/login')}>SE CONNECTER</button>
        </div>

        <iframe className='awlad_video' src={`https://player.vimeo.com/video/535138410?title=0&amp;autoplay=0&amp;controls=1&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autoplay=0&amp;player_id=0&amp;app_id=58479&amp;fullscreen=1`} allowFullScreen width="1920" height="1440" allow="autoplay; fullscreen; picture-in-picture" title="Foulane Camping V1"></iframe>
        <img src='images/homepage_foulane.jpg' alt='La Famille Foulane' className='hp-foulane' />
        <img src='images/homepage_guides.jpg' alt='Les mini guides du BDouin' className='hp-guides' />
        <img src='images/homepage_mshow.jpg' alt='Muslim Show' className='hp-mshow' />
        <img src='images/homepage_quiz.jpg' alt='Les Quizs du BDouin' className='hp-quiz' />

        {process.env.REACT_APP_ENABLE_AD === '1' && (
          <BtnEidGift className='hp-hoopow-homepage-eid-gift ks-clickable' onClick={() => setModalIsOpen(true)} />
        )}
        <HoopowLogo ref={hoopowLogoDiv} className='hp-hoopow-logo' />
        <div className='hp-hoopow-faq'>
          <FAQs />
        </div>

        <div ref={signupDiv} className='hp-homepage-signup'>
          <div className='hp-homepage-signup-title'>Créer un compte</div>
          <div className='hp-homepage-signup-form'>
            <SignUpForm {...props} />
            <div className='hp-homepage-signup-form-freeperiod'>14 JOURS D’ESSAI GRATUIT</div>
            <div className='hp-homepage-signup-nocontract'>SANS ENGAGEMENT</div>
          </div>
        </div>
        <Link to='/cgu' className='hp-homepage-signup-cgu'>CONDITIONS GÉNÉRALES - MENTIONS LÉGALES</Link>
        <HomepageMobileBG className='hp-homepage-notconnected-background-mobile' />
        <HomepageBG className='hp-homepage-notconnected-background' />
      </div>
    </>

  );
}

export default Homepage;
