import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { trim } from "../../utils/Constants";
import './MemberForm.scss'

const MemberForm = (props: { user?: any, history: any }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = props;

  const [formData, setFormData] = useState({
    name: user.name,
    email: user.email,
    newPassword: '',
    confirmationNewPassword: '',
    oldPassword: ''
  });
  const handleChange = (e: any, prop: string) => {
    setFormData({
      ...formData,
      [prop]: e.target ? e.target.value : e
    });
  };

  const isValidForm = () => {
    return trim(formData.name) !== user.name
      || (formData.oldPassword !== '' && formData.newPassword === formData.confirmationNewPassword
        && formData.newPassword && formData.newPassword !== '');
  }

  useEffect(() => {
    setFormData({
      name: user.name,
      email: user.email,
      newPassword: '',
      confirmationNewPassword: '',
      oldPassword: ''
    });
  }, [user])

  return (
    <div className='hp-memberForm'>
      <div className="hp-profil">
        <form className='hp-form'>
          <div className="ks-form-group">
            <label className="ks-form-label">Nom d’utilisateur :</label>
            <input type='text' placeholder="Tapez ici votre nom" onChange={(e) => handleChange(e, 'name')}
              className="ks-form-control" value={formData.name ? formData.name : ''} />
          </div>
          <div className="ks-form-group">
            <label className="hp-form-label">E-mail :</label>
            <input type='text' placeholder="Tapez ici votre email" onChange={(e) => handleChange(e, 'email')}
              className="ks-form-control" value={formData.email ? formData.email : ''} disabled={true}
              autoComplete='username' />
          </div>
          <div className="ks-form-group">
            <label className="hp-form-label">Nouveau mot de passe :</label>
            <input type='password' placeholder="Tapez ici votre nouveau mot de passe" onChange={(e) => handleChange(e, 'newPassword')}
              className="ks-form-control" value={formData.newPassword ? formData.newPassword : ''}
              autoComplete='new-password' />
          </div>
          <div className="ks-form-group">
            <label className="hp-form-label">Confirmation du mot de passe :</label>
            <input type='password' placeholder="Confirmez ici votre nouveau mot de passe" onChange={(e) => handleChange(e, 'confirmationNewPassword')}
              className="ks-form-control" value={formData.confirmationNewPassword ? formData.confirmationNewPassword : ''}
              autoComplete='new-password' />
          </div>
          <div className="ks-form-group">
            <label className="hp-form-label">Ancien mot de passe :</label>
            <input type='password' placeholder="Tapez ici votre ancien mot de passe" onChange={(e) => handleChange(e, 'oldPassword')}
              className="ks-form-control" value={formData.oldPassword ? formData.oldPassword : ''}
              autoComplete='current-password' />
          </div>
        </form>
        <button className='mainBtn' disabled={!isValidForm() || loading} onClick={async () => {
          if (loading) return;
          setLoading(true);

          try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, {
              'name': trim(formData.name)
            });
            if (formData.newPassword && formData.newPassword !== '') {
              await Auth.changePassword(
                currentUser,
                formData.oldPassword,
                formData.newPassword
              );
            }
            setFormData({
              name: trim(formData.name),
              email: formData.email,
              newPassword: '',
              confirmationNewPassword: '',
              oldPassword: ''
            })
          } catch (error: any) {
            if (error.code && error.code === 'NotAuthorizedException') {
              alert('Le mot de passe est invalide.');
            } else if(error.code && (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException')) {
              alert('Votre mot de passe doit contenir au minimum 8 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.');
            } else {
              alert(error.code);
            }
          }
          setLoading(false);
        }}>CONFIRMER MODIFICATION</button>
      </div>
    </div >
  );
}
export default MemberForm;