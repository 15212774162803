import './EidGiftLayout.scss';
import { ReactComponent as HoopowLogo } from '../../assets/hoopow-logo.svg';
import { ReactComponent as CloseBtn } from '../../assets/close-btn.svg';
import { useHistory } from 'react-router-dom';

function EidGiftLayout(props: { closeScreen?: Function, headerComponent?: any, children: any }) {
  const { closeScreen, headerComponent } = props;
  const history = useHistory();

  const closeBtnHandler = (legacyAction: Function) => {
    if (parseInt(process.env.REACT_APP_FEATURE_FREEMIUM || '0') === 1) {
      history.push('/');
    } else {
      legacyAction();
    }
  }

  return (
    <div className='hp-eidgift'>
      {closeScreen && (
        <CloseBtn className='hp-closeBtn ks-clickable' onClick={() => closeBtnHandler(closeScreen)} />
      )}
      {headerComponent ? (headerComponent) : (
        <HoopowLogo className="hp-eidgift-logo" />
      )}
      {props.children}
    </div>);
}

export default EidGiftLayout;
