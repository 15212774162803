import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import EidGiftCoupon from '../modal/EidGiftCoupon';
import EidGiftLayout from '../modal/EidGiftLayout';


function GiftCoupon() {
  const history = useHistory();
  const { couponId } = useParams<any>();

  return (
    <div className="hp-giftcoupon">
      <EidGiftLayout closeScreen={() => history.push('/')}>
        <EidGiftCoupon couponId={couponId} />
      </EidGiftLayout>

    </div>
  );
}

export default GiftCoupon;
