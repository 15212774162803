import { useRef } from "react";

const PasswordField = (props: any) => {

  const passwordFieldRef = useRef<any>();
  const iconRef = useRef<any>();
  return (<div className="password-wrapper">
    <input ref={passwordFieldRef} type="password" autoComplete={props.autoComplete} name="password" placeholder={props.placeholder} onChange={props.onChange} className={props.className} />
    <div className="eye-icon-wrapper" onClick={() => {
      if (passwordFieldRef.current?.getAttribute('type') === 'password') {
        passwordFieldRef.current?.setAttribute('type', 'text');
        iconRef.current.classList.add('bi-eye-slash');
        iconRef.current.classList.remove('bi-eye');
      } else {
        passwordFieldRef.current?.setAttribute('type', 'password');
        iconRef.current.classList.add('bi-eye');
        iconRef.current.classList.remove('bi-eye-slash');
      }
    }}>
      <i ref={iconRef} className="bi eye-icon bi-eye" ></i>
    </div>
  </div>);
}
export default PasswordField;