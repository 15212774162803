import EmptyOrder from './components/EmptyOrder';
import './MyOrders.scss';
import { ReactComponent as MyOrderIcon } from './assets/icon-myorders.svg';
import React, { useEffect, useState } from 'react';
import BaseDS from '../../datastore/BaseDS';
import OrderElement from './components/OrderElement';
import { ClipLoader } from 'react-spinners';

function MyOrders() {
  const [purchases, setPurchases] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    setLoading(true);
    BaseDS.retrievePurchases().then((data: any) => {
      setPurchases(data);
    }).finally(() => setLoading(false));
  }, [])

  return (
    <div className="hp-myorders">
      <div className="hp-myorders-title"><MyOrderIcon />mes achats</div>
      <div className="hp-myorders-list">
        {loading ? (<ClipLoader />) : (<>
          {Array(purchases.length > 4 ? (purchases.length % 2 === 0 ? purchases.length : purchases.length + 1) : 4).fill(1).map((one: number, index: number) => (
            <React.Fragment key={index}>
              {index % 2 === 0 && index !== 0 && (<>
                <br />
                <div className='separator'></div>
                <br />
              </>)}
              <div className={`hp-myorders-element ${index % 2 !== 0 && index !== 0 && "odd"}`}>
                {purchases && purchases[index] ? (<OrderElement orderDetails={purchases[index]} />) : (
                  <EmptyOrder />
                )}
              </div>
            </React.Fragment>))}
        </>)}
      </div>
    </div>
  );
}

export default MyOrders;
