import './AppCard.scss';
import SVG from '../elements/SVG';

const AppCard = (props: any) => {
  const { appDef, enabled, isPublished } = props;

  return (<>
    <div className='hp-appCard'>
      {appDef && appDef.imageHomepage !== '' ? (<>
        <SVG src={appDef.imageHomepage} onClick={() => { if (enabled && isPublished) document.location.href = appDef.url; }}
          className={`ks-clickable ks-appCard-thumbnail ks-appCard-thumbnail-landscape ${!enabled || !isPublished ? ' ks-disabled' : ''}`} />
          <SVG src={appDef.imagePhoneHomepage ? appDef.imagePhoneHomepage : appDef.imageHomepage} onClick={() => { if (enabled && isPublished) document.location.href = appDef.url; }}
            className={`ks-clickable ks-appCard-thumbnail ${isPublished} ks-appCard-thumbnail-portrait ${!enabled || !isPublished ? ' ks-disabled' : ''}`} />
      </>) : (
        <div className='hp-appCard-empty'>
          <div className='hp-appCard-emptyBox ks-boxShadow'>
            <span>PROCHAINEMENT</span>
          </div>
        </div>
      )}
    </div>

  </>)
};

export default AppCard;