import { Redirect } from 'react-router-dom'
import TitledRoute from './common/internalrouter/TitledRoute';

const PrivateRoute = (props: any) => {
  const { authed, ...rest } = props;

  return (
    <>
      {authed ? (
        <TitledRoute {...rest} />
      ) : (<Redirect to="/login" />)}
    </>)
}

export default PrivateRoute