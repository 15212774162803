import './css/CGU.scss'
import { ReactComponent as TopBanner } from '../assets/cgu-top-banner.svg';
import { ReactComponent as Cgu } from '../assets/cgu.svg';
import { ReactComponent as IconBack } from '../assets/icon-back.svg';
import { ReactComponent as CloseBtn } from '../assets/close-btn.svg';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

function CGU(props: { isEmbedded: boolean, isAuthenticated: boolean }) {
  const history = useHistory();
  useEffect(() => {
    if (!props.isAuthenticated) {
      document.body.classList.add('hp-body-homepage-notconnected');
      document.body.classList.add('hp-body-homepage-notconnected-cgu');
      document.body.scrollTop = 0;
    }
  }, [props.isAuthenticated]);
  return (
    <>
      {!props.isAuthenticated && (<>
        <div className='ks-auth-topBanner hp-topBanner-cgu'>
          <TopBanner />
        </div>
        <div className='ks-homepage-notconnected-topBtn'>
          <button className='mainBtn' onClick={() => history.push('/signup')}>S’INSCRIRE</button>
          <button className='mainBtn hp-homepage-connectionBtn' onClick={() => history.push('/login')}>SE CONNECTER</button>
        </div>
      </>)}
      <div className={`hp-cgu`}>
        <CloseBtn className='hp-closeBtn ks-clickable' onClick={() => history.push('/')} />
        <Link to='/' className='hp-back'><IconBack /><div>Retour à l’accueil</div></Link>
        <Cgu className='hp-cgu-text' />
        <Link to='/' className='hp-back'><IconBack /><div>Retour à l’accueil</div></Link>
      </div>
    </>

  );
}

export default CGU;
