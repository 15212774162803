import { useEffect, useRef, useState } from 'react';
import './Feed.scss';
import { ReactComponent as SoundIcon } from '../../assets/v2/voice_icon.svg';
import { ReactComponent as StopIcon } from '../../assets/stop_audio.svg';
import { ReactComponent as CloseBtn } from '../../assets/closeBtn_withBg.svg';
import ImageComponent from '../elements/ImageComponent';
import InnerHtml from '../elements/InnerHtml';
import { ClipLoader } from 'react-spinners';
import AudioComponent from '../elements/AudioComponent';
import { TFeedItem } from '../../types';
import Vimeo from "@vimeo/player";

const videoVolume = 0.6;

const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);

const iOS13_iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

const iOS1to12quirk = function () {
  var audio = new Audio(); // temporary Audio object
  audio.volume = videoVolume; // has no effect on iOS <= 12
  return audio.volume === 1;
};

const isIOS = !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk());

function FeedItem(props: {
  item: TFeedItem, contentOpen: boolean, setContentOpen: Function, isPortrait: boolean,
  currentArticleIndex: number, setCurrentArticleIndex: Function, nbTotalArticle: number
}) {
  const { item, contentOpen, setContentOpen, isPortrait, currentArticleIndex, setCurrentArticleIndex, nbTotalArticle } = props;
  const audioRef = useRef<any>();
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
  const [loadingVideo, setLoadingVideo] = useState<boolean>(true);
  const [player, setPlayer] = useState<Vimeo>()
  const videoRef = useRef<any>();

  const stopAudio = () => {
    setAudioPlaying(false)
    if (audioRef && audioRef.current)
      audioRef?.current.stop();
  }

  const playAudio = () => {
    setAudioPlaying(true);
    player?.setVolume(videoVolume);
    player?.setMuted(false)
    player?.play()
    if (audioRef && audioRef.current)
      audioRef?.current.play();
  }

  useEffect(() => {
    if (videoRef.current && !player && item.video) {
      console.log('init vimeo player');
      const vimeoPlayer: Vimeo = new Vimeo(videoRef.current);
      if (!isIOS)
        vimeoPlayer.setVolume(videoVolume);
      setPlayer(vimeoPlayer);
    }
  }, [videoRef, player, item.video]);

  useEffect(() => {
    if (player && !isIOS) {
      if (item.video && !loadingVideo) player.play();
      else player.pause()
    }
  }, [item.video, loadingVideo, player]);

  useEffect(() => {
    if (player && !isIOS) {
      if (!contentOpen) player.pause();
      else player.play();
    }
  }, [player, contentOpen])

  const stopVideo = () => {
    if (player) player.pause();
  }

  return (
    <>
      <AudioComponent ref={audioRef} audioKey={item.audio} onEnded={stopAudio} />
      <div className={`hp-feed-frame-content-media`}>
        {item.video ? (<>
          <iframe key={'video' + item.id} ref={videoRef} src={`https://player.vimeo.com/video/${isPortrait ? item.videoMobile : item.video}?title=0&amp;muted=${isIOS ? 1 : 0}&amp;loop=1&amp;controls=0&amp;byline=0&amp;portrait=1&amp;speed=0&amp;badge=0&amp;autoplay=${isIOS ? 1 : 0}&amp;player_id=0&amp;app_id=58479&amp;fullscreen=1`}
            allowFullScreen allow="autoplay; fullscreen; picture-in-picture" title={item.name}
            style={{ display: !loadingVideo ? 'block' : 'none' }}
            onLoad={() => setLoadingVideo(false)} />

          <div className='hp-imageLoader' style={{ display: loadingVideo ? 'flex' : 'none' }}>
            <ClipLoader />
          </div>
        </>) : (<>
          {isPortrait ? (
            <ImageComponent imageKey={item.imageMobile} alt='imagefeed' />
          ) : (
            <ImageComponent imageKey={item.image} alt='imagefeed' />
          )}
        </>)}
      </div>
      <div className={`hp-feed-frame-content-description`}>
        <div className={`hp-feed-frame-content-title`}>
          <div className={`hp-feed-frame-content-title-latin ks-clickable`}
            onClick={() => audioPlaying ? stopAudio() : playAudio()}>{item.name}
            {!audioPlaying ? (<SoundIcon />) : (<StopIcon />)}
          </div>
        </div>
        <InnerHtml className={`hp-feed-frame-content-text`} text={item.description} />
        <div className={`hp-feed-frame-content-btn`}>
          <button className={`mainBtn`} disabled={currentArticleIndex < 1} onClick={() => {
            stopAudio()
            stopVideo()
            setLoadingVideo(true)
            setCurrentArticleIndex((prev: number) => prev - 1)
          }}>PRÉCÉDENT</button>
          <div className={`pagination`}>{currentArticleIndex + 1}&nbsp;/&nbsp;{nbTotalArticle}</div>
          <button className={`mainBtn`}
            disabled={currentArticleIndex === nbTotalArticle - 1}
            onClick={() => {
              stopAudio()
              stopVideo()
              setLoadingVideo(true)
              setCurrentArticleIndex((prev: number) => prev + 1)
            }}>SUIVANT</button>
        </div>
      </div>
      <div className={`hp-feed-frame-third-column`}>
        <CloseBtn className={`hp-feed-closeBtn ks-clickable`} onClick={() => {
          stopAudio()
          stopVideo()
          setContentOpen(false)
        }} />
      </div>
    </>
  );
}

export default FeedItem;
