import { useRef } from 'react';
import './EidGiftCardTemplate.scss'
import { ReactComponent as EidCard6Template } from '../../assets/eid-gift-card-6months.svg';
import { ReactComponent as EidCard12Template } from '../../assets/eid-gift-card-12months.svg';
import { ReactComponent as Card6Template } from '../../assets/gift-card-6months.svg';
import { ReactComponent as Card12Template } from '../../assets/gift-card-12months.svg';

function EidGiftCardTemplate(props: { couponCode: string, planId: string, eventType?: string }) {
  const { couponCode, planId, eventType } = props;
  const templateRef = useRef<any>();

  return (
    <div id={`eidgift-card-${couponCode}`} className='hp-eidgift-customer-card'>
      {planId === 'year_gift_plan'
        ? (<>{eventType === 'unknown' ? (<Card12Template ref={templateRef} />) : (<EidCard12Template ref={templateRef} />) }</>)
        : (<>{eventType === 'unknown' ? (<Card6Template ref={templateRef} />) : (<EidCard6Template ref={templateRef} />) }</>)}
      <label>{couponCode}</label>
    </div>
  );
}

export default EidGiftCardTemplate;
