import { useEffect } from "react";
import './SignUp.scss'
import SignUpForm from "../elements/SignupForm";
import SignUpFormV3 from "../elements/SignupFormV3";

const CustomSignUp = (props: any) => {
  useEffect(() => {
    document.body.classList.remove('hp-body-homepage-notconnected');
  }, []);

  useEffect(() => {
    document.title = "HOO POW | S'INSCRIRE";
  }, []);

  return (
    <div slot="sign-up" className='ks-auth-signUp'>
      {process.env.REACT_APP_FEATURE_NEW_DESIGN_V3 === '1' ? (
        <SignUpFormV3 {...props} />
      ) : (
        <SignUpForm {...props} />
      )}
    </div>
  );
}
export default CustomSignUp;