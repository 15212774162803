import { createContext, useContext, useEffect, useState } from "react";

import { TFeedItem, TMainConfig } from "../../types";
import BaseDS from "../../datastore/BaseDS";
import { UserContext } from "./UserContext";

const removeAllDash = (value: any) => {
  return value ? value.replace(/-/g, '') : value;
}

export const MainConfigContext = createContext<any>("");

const MainConfigContextProvider = (props: any) => {

	const { isUserAuthenticated } = useContext(UserContext);
  const [content, setContent] = useState<any>();
	const [mainConfig, setMainConfig] = useState<TMainConfig>();
	const [advertsConfig, setAdvertsConfig] = useState<TMainConfig>();
	const [appById, setAppById] = useState<any>();
	const [loading, setLoading] = useState<boolean>(true);
  const [loadingFeed, setLoadingFeed] = useState<boolean>(true);

	useEffect(() => {
    BaseDS.getFeeds().then(async (data: any) => {
      data.map((rubric: any) => {
        const filteredFeedItem = rubric.feedItem.filter((a: TFeedItem) => a.publicationDate && parseInt(removeAllDash(a.publicationDate)) <= parseInt(removeAllDash(new Date().toISOString().split('T')[0])));
        filteredFeedItem.sort((a: TFeedItem, b: TFeedItem) => parseInt(removeAllDash(b.publicationDate)) - parseInt(removeAllDash(a.publicationDate)))
        rubric.feedItem = filteredFeedItem;
        return rubric;
      });
      setContent(data);
    }).finally(() => setLoadingFeed(false))
  }, [])

	useEffect(() => {
		if (!mainConfig && (isUserAuthenticated || process.env.REACT_APP_FEATURE_FREEMIUM === '1')) {
			BaseDS.getAllMainDef().then((mainConfigTmp) => {
				setMainConfig(mainConfigTmp[0]);
				if (mainConfigTmp[0].application) {
					const appByIdTmp: any = {};
					mainConfigTmp[0].application.forEach((appDefTmp: any) => {
						appByIdTmp[appDefTmp.id] = appDefTmp;
					});
					setAppById(appByIdTmp);
				}
				if (mainConfigTmp[1]) setAdvertsConfig(mainConfigTmp[1]);
			}).finally(() => setLoading(false));
		}
	}, [isUserAuthenticated, mainConfig]);

	return (
		<MainConfigContext.Provider value={{ loadingFeed, content, mainConfig, advertsConfig, appById, loading }} >
			{props.children}
		</MainConfigContext.Provider>
	);
};

export default MainConfigContextProvider;
