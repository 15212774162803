import { useState } from 'react';
import { useHistory } from 'react-router';
import './ContactUs.scss';
import { ReactComponent as Enveloppe } from '../../assets/v2/enveloppe.svg';
import { ReactComponent as CloseBtn } from '../../assets/close-btn.svg';
import { NavLink } from 'react-router-dom';
import BaseDS from '../../datastore/BaseDS';


function ContactUs() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState<boolean | string>(false);
  const [formData, setFormData] = useState({
    subject: '',
    name: '',
    email: '',
    bodyText: '',
    mentionCheckbox: false
  });
  const handleInputChange = (e: any, prop: string) => {
    console.log(e.target);
    setFormData({
      ...formData,
      [prop]: prop === "mentionCheckbox" ? e.target.checked : e.target.value
    });
  };

  const submitForm = async () => {
    setLoading(true);
    BaseDS.contactus(formData.subject, formData.name, formData.email, formData.bodyText).then(() => {
      setMessageSent(true);
    }).catch(() => setMessageSent('error'))
      .finally(() => setLoading(false));
  }

  const isValidForm = () => {
    console.log(formData.mentionCheckbox)
    return !(formData.name === '' ||
      formData.email === '' ||
      formData.subject === '' ||
      formData.bodyText === '' ||
      !formData.mentionCheckbox)
  }
  return (
    <div className="hp-contactus">
      <CloseBtn className='hp-closeBtn ks-clickable' onClick={() => history.push('/')} />
      <div className='ks-form'>
        <form>
          <div className="ks-form-title"> NOUS ENVOYER UN MESSAGE </div>
          <div className="ks-form-group">
            <label className="ks-form-label">Sujet:</label>
            <input type='text' autoComplete="off" placeholder="Tapez ici la raison de votre message" onChange={(e) => handleInputChange(e, 'subject')}
              className="ks-form-control"
            />
          </div>
          <div className="ks-form-group-sameline">
            <div className="ks-form-group">
              <label className="ks-form-label">Nom</label>
              <input type="text" placeholder="Tapez ici votre nom" onChange={(e) => handleInputChange(e, 'name')} className="ks-form-control" />
            </div>
            <div className="ks-form-group">
              <label className="ks-form-label">E-mail</label>
              <input type='email' placeholder="Tapez ici votre email" onChange={(e) => handleInputChange(e, 'email')}
                className="ks-form-control"
              />
            </div>
          </div>
          <div className="ks-form-group">
            <textarea autoComplete="off" placeholder="Écrivez votre message ici..." onChange={(e) => handleInputChange(e, 'bodyText')} className="ks-form-control" />
          </div>
          <div className="ks-form-group-checkbox">
            <input id="mentionCheckbox" type='checkbox' onChange={(e) => handleInputChange(e, 'mentionCheckbox')} className="ks-form-control" />
            <label className="ks-form-label" htmlFor="mentionCheckbox">J’accepte les Conditions Générales d’Utilisation et que HooPow traite mes données personnelles pour gérer mes contributions telles que définies dans les CGU. Pour aller plus loin, consultez notre <NavLink to='/cgu'><b>politique de confidentialité.</b></NavLink></label>
          </div>
        </form>
        <div className="ks-formBtn">
          {!messageSent ? (
            <button className="mainBtn" onClick={() => { submitForm() }} type="submit" disabled={!isValidForm() || loading}>
              <Enveloppe />ENVOYER
            </button>
          ) : (<>{messageSent !== 'error' ? (<>Merci de votre message!<br />
            Nous ne manquerons pas de vous répondre dans les meilleurs délais.
            </>) : (<>Une erreur s'est produite lors de la tentative d'envoi de votre message. <br />Veuillez réessayer plus tard.</>)}
            </>)}
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
