import {Auth} from 'aws-amplify';
import {allowedToEmbbedFrame} from "../../utils/Constants";
import UserForm from "./UserForm";

const SignUpFormV3 = (props: any) => {
    const urlParams = new URLSearchParams(props.location.search);
    const appId: any = urlParams.get('appId');

    const submit = async (formData: any, callback: Function) => {

        try {
            const params: any = {
                username: formData.email.trim(),
                password: formData.password,
                attributes: {
                    email: formData.email.trim(),
                    name: formData.name.trim(),
                    'custom:avatarId': formData.avatarId.trim()
                }
            }
            await Auth.signUp(params);
            await Auth.signIn(formData.email.trim(), formData.password);
            if (window.parent) {
                window.parent.postMessage({'step': 'signedup'}, allowedToEmbbedFrame(appId));
            }
            callback();
        } catch (error: any) {
            let errorMessage = '';
            if (error.code && error.code === 'UsernameExistsException') {
                errorMessage = 'Cette adresse e-mail est déjà utilisée. N’hésitez pas à cliquer sur Mot de passe oublié en cas de besoin.';
            } else if (error.code && (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException') && error.message.indexOf("Invalid email address format") > -1) {
                errorMessage = "Format d'e-mail non valide.";
            } else if (error.code && (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException')) {
                errorMessage = 'Votre mot de passe doit contenir au minimum 8 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.';
            } else {
                errorMessage = "Erreur: " + error.message;
            }
            console.log('error Loading');
            callback(errorMessage);
        }
    }

    const isValidForm = (formData: any) => {
        return !(formData.name === '' ||
            formData.email === '' ||
            formData.password === '' ||
            formData.avatarId === '')
    }

    return (<>
            <UserForm submit={submit} isValidForm={isValidForm} onMobile={props.onMobile}
                      showAvatar={props.showAvatar}/>
        </>
    );
}
export default SignUpFormV3;