import './AvatarSelector.scss'
import {ReactComponent as ManIcon} from '../../assets/v3/avatar/man-icon.svg';
import {ReactComponent as WomanIcon} from '../../assets/v3/avatar/woman-icon.svg';
import {ReactComponent as GirlIcon} from '../../assets/v3/avatar/girl-icon.svg';
import {ReactComponent as BoyIcon} from '../../assets/v3/avatar/boy-icon.svg';
import {useEffect, useState} from 'react';
import BaseDS from '../../datastore/BaseDS';
import FileDS from '../../datastore/FileDS';
import {ClipLoader} from 'react-spinners';

const avatarCache: any = [];

const AvatarSelector = (props: { setAvatarId: Function, setAvatarSrc?: Function, setSelectingIcon?: any, onMobile?: boolean, className?: string }) => {
    const [selectedAvatarGender, setSelectedAvatarGender] = useState<string>('man');
    const [selectedAvatar, setSelectedAvatar] = useState<number>();
    const [avatarList, setAvatarList] = useState<any[]>();

    useEffect(() => {
        BaseDS.fecthAvatarListByGender(selectedAvatarGender).then((data) => {
            avatarCache[selectedAvatarGender] = data;
            setAvatarList(data);
        })
    }, [selectedAvatarGender])

    const selectGender = (genderKey: string) => {
        if (genderKey !== selectedAvatarGender) {
            setSelectedAvatarGender(genderKey);
            setSelectedAvatar(-1);
        }
    }

    function getAvatar(avatarDef: any, index: any) {
        const avatarSrc = FileDS.getUrl(avatarDef.thumbnailKey.replace("512x", "thumbnails"));
        return (
            <div key={index}
                 className={`ks-avatar-selector__list-item ${selectedAvatar === index && 'selected'}`}>
                <img alt={avatarDef.thumbnailKey} loading="lazy"
                     src={avatarSrc}
                     className={``} onClick={() => {
                    console.log(avatarDef, avatarDef.thumbnailKey.replace("512x", "thumbnails"));
                    setSelectedAvatar(index);
                    props.setAvatarId(avatarDef.avatarKey)
                    if (props.setAvatarSrc) props.setAvatarSrc(avatarSrc)
                }}/>
            </div>
        )
    }

    return (
        <div className={"ks-avatar-selector " + props.className}>
            <div className="ks-avatar-selector__title">Choisissez votre image de profil</div>
            <div className="ks-avatar-selector__gender">
                <ManIcon className={`${selectedAvatarGender === 'man' && 'selected'}`}
                         onClick={() => selectGender('man')}/>
                <WomanIcon className={`${selectedAvatarGender === 'woman' && 'selected'}`}
                           onClick={() => selectGender('woman')}/>
                <GirlIcon className={`${selectedAvatarGender === 'girl' && 'selected'}`}
                          onClick={() => selectGender('girl')}/>
                <BoyIcon className={`${selectedAvatarGender === 'boy' && 'selected'}`}
                         onClick={() => selectGender('boy')}/>
            </div>
            <div className="ks-avatar-selector__list">
                {!avatarList ? (
                        <div className="loader"><ClipLoader size={50}/></div>) :
                    props.onMobile ? (
                        <>
                            <div className='ks-avatar-selector__list-flex'>
                                {avatarList.slice(0, Math.floor(avatarList.length / 3))
                                    .map((avatarDef: any, index: number) => getAvatar(avatarDef, index))}
                            </div>
                            <div className='ks-avatar-selector__list-flex'>
                                {avatarList.slice(Math.floor(avatarList.length / 3), Math.floor(2 * avatarList.length / 3))
                                    .map((avatarDef: any, index: number) => getAvatar(avatarDef, index + Math.floor(avatarList.length / 3)))}
                            </div>
                            <div className='ks-avatar-selector__list-flex'>
                                {avatarList.slice(Math.floor(2 * avatarList.length / 3), avatarList.length)
                                    .map((avatarDef: any, index: number) => getAvatar(avatarDef, index + Math.floor(2 * avatarList.length / 3)))}
                            </div>
                        </>) : (<>
                        {avatarList.map((avatarDef: any, index: number) => getAvatar(avatarDef, index))}
                    </>)}
            </div>
            <button className='choose-btn' onClick={() => {
                if (selectedAvatar && selectedAvatar > -1 && props.setSelectingIcon) props.setSelectingIcon(false)
            }}>CHOISIR
            </button>
        </div>
    );
}
export default AvatarSelector;