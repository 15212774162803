import { ReactComponent as IconWho } from '../assets/icon-who.svg';
import { ReactComponent as IconWhat } from '../assets/icon-what.svg';
import { ReactComponent as IconSupport } from '../assets/icon-support.svg';
import { ReactComponent as BannerWho } from '../assets/banner-who.svg';
import { ReactComponent as BannerWhat } from '../assets/banner-what.svg';
import { ReactComponent as BannerSupportUs } from '../assets/banner-supportus.svg';

import { ReactElement } from 'react';

export type TExplanationsInfo = {
  [key: string]: TExplanationInfoItem
}

export type TExplanationInfoItem = {
  title: string,
  titleImage: ReactElement<any, any>,
  bottomImage: ReactElement<any, any>,
  text?: ReactElement<any, any>
}


export const explanationInfo: TExplanationsInfo = {
  who: {
    title: "BDouin, c’est qui ?",
    titleImage: <IconWho />,
    bottomImage: <BannerWho />,
    text: <>Fondée en 2009 par Norédine et Karim Allam, à Amiens (France), le BDouin est une maison d’édition spécialisée dans la production de contenu jeunesse dédiée à la famille musulmane.<br />
      Sous la direction artistique de Norédine, le BDouin ambitionne de devenir une alternative éthique à l’offre de divertissement présente sur le marché.<br />
      Mais au-delà du côté ludique, le BDouin a également pour mission de rendre accessible à tous, de façon plaisante et illustrée, des connaissances bénéfiques.<br />
      Aujourd’hui constituée d’une équipe de plus de 20 personnes (localisée dans le monde entier), le studio BDouin consacre l’ensemble de son temps à des projets tels que :</>,
  },
  what: {
    title: "Hoopow, c’est quoi ?",
    titleImage: <IconWhat />,
    bottomImage: <BannerWhat />,
    text: <>C’est une plateforme web réunissant du contenu ludo-éducatif dédié à la famille musulmane.<br />
    Sécurisé, autonome et supervisé par une équipe dirigée par Norédine Allam, fondateur du studio BDouin, Hoopow aspire à devenir un endroit sain où parents et enfants se réunissent pour partager une expérience enrichissante.<br />
    <br />
    Fatigués des réseaux sociaux et des applications malsaines pour accéder à du contenu intéressant ? Vous êtes à la bonne adresse, incha Allah !</>,
  },
  supportus: {
    title: "3 façons de nous soutenir",
    titleImage: <IconSupport />,
    bottomImage: <BannerSupportUs />
  }
}