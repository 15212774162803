import ApiWrapper from './ApiWrapper';
import { TMainConfig, TPaymentInfo } from '../types'

const apiName = 'ApiGatewayRestApi';
const unsecuredApiName = 'UnsecuredApi';

export default class BaseDS {

  static getMainDef = async (): Promise<TMainConfig> => {
    return ApiWrapper.get(apiName, '/main/first');
  };

  static getFeeds = async (): Promise<TMainConfig> => {
    return ApiWrapper.get(apiName, '/allfeeds');
  };

  static getProductDetailsById = async (productId: string): Promise<any> => {
    return ApiWrapper.get(apiName, '/product/' + productId);
  };

  static getPaymentInfos = async (): Promise<any> => {
    return ApiWrapper.get(apiName, '/paymentInfo');
  };

  static updateUserPaymentInfos = async (paymentMethodId: string): Promise<any> => {
    return ApiWrapper.put(apiName, '/userPaymentInfo', { body: { paymentMethodId } });
  };

  static getUserPaymentInfo = async (): Promise<any> => {
    return ApiWrapper.get(apiName, '/userPaymentInfo');
  };

  static getAllMainDef = async (): Promise<TMainConfig[]> => {
    return ApiWrapper.get(apiName, '/main/all');
  };

  static createSession = async (planId: string, name: string, email: string, quantity: number): Promise<any> => {
    return ApiWrapper.post(apiName, '/payment/checkout', { body: { planId, email, quantity, name } });
  };

  static createPurchase = async (productId: string, itemId: string, email: string, paymentMethodId: string, quantity?: number, paymentInfo?: TPaymentInfo): Promise<any> => {
    return ApiWrapper.post(apiName, '/purchase/create', { body: { productId, email, paymentMethodId, itemId, quantity, paymentInfo } });
  };

  static verifyPurchase = async (productId: string, itemId: string): Promise<any> => {
    return ApiWrapper.put(apiName, '/purchase/verify', { body: { productId, itemId } });
  };

  static retrievePurchases = async (): Promise<any> => {
    return ApiWrapper.get(apiName, '/purchase', {});
  };

  static retrieveSession = async (sessionId: string): Promise<any[]> => {
    return ApiWrapper.post(apiName, '/coupon', { body: { sessionId } });
  };

  static retrieveCoupon = async (couponId: string): Promise<any[]> => {
    return ApiWrapper.get(apiName, '/coupon/' + couponId, {});
  };

  static retrieveAllCoupon = async (): Promise<any[]> => {
    return ApiWrapper.get(apiName, '/coupon');
  };

  static applyCoupon = async (couponId: string): Promise<any[]> => {
    return ApiWrapper.post(apiName, '/coupon/apply', { body: { couponId } });
  };

  static fecthAvatarListByGender = async (genderKey: string, offset?: number): Promise<any[]> => {
    return ApiWrapper.get(apiName, '/avatar/' + genderKey + "/" + (offset || "0"));
  };

  static contactus = async (subject: string, name: string, email: string, bodyText: string): Promise<any[]> => {
    return ApiWrapper.post(unsecuredApiName, '/contactus', { body: { email, name, subject, bodyText } });
  };

}

