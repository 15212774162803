import {useEffect, useState} from 'react';
import SignInForm from '../elements/SignInForm';
import SignUpFormV3 from '../elements/SignupFormV3';
import './EmbeddedAuthentication.scss'

export enum tabs {
    SIGNUP,
    SIGNIN
}

const EmbeddedAuthentication = (props: any) => {
    const url = new URL(window.location.href);
    const initialState = url.searchParams.get("initialState");
    const onMobile = url.searchParams.get("isMobile") === "true";
    const [currentTab, setCurrentTab] = useState<tabs>(tabs[initialState as keyof typeof tabs] || tabs.SIGNUP);

    return (<>
        <div className={"ks-embedded-authentication " + (onMobile ? "mobile-only" : "desktop-only")}>
            <div className="ks-form-title">
                <span onClick={() => setCurrentTab(tabs.SIGNUP)}
                      className={`${tabs.SIGNUP === currentTab && "selected"}`}>S’INSCRIRE</span>
                <span className={"desktop-only"}>&nbsp;|&nbsp;</span>
                <span onClick={() => setCurrentTab(tabs.SIGNIN)}
                      className={`${tabs.SIGNIN === currentTab && "selected"}`}>SE CONNECTER</span>
            </div>
            <div className="ks-loginV3">
                {currentTab === tabs.SIGNUP ? (
                    <SignUpFormV3 {...props} showAvatar/>
                ) : (
                    <SignInForm {...props} />)}
            </div>
        </div>
    </>);
}
export default EmbeddedAuthentication;