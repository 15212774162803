import { API } from 'aws-amplify';

const apiName = 'UnsecuredApi';

export default class UnsecuredDS {
  static createSession = async (planId: string, email: string, quantity: number): Promise<any> => {
    return API.post(apiName, '/payment/checkout', { body: {planId, email, quantity} });
  };

  static retrieveSession = async (sessionId: string): Promise<any[]> => {
    return API.post(apiName, '/coupon', { body: {sessionId} });
  };

  static retrieveCoupon = async (couponId: string): Promise<any[]> => {
    return API.get(apiName, '/coupon/' + couponId, {} );
  };

}

