import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import EidGift from '../modal/EidGift';
import EidGiftCoupon from '../modal/EidGiftCoupon';
import EidGiftLayout from '../modal/EidGiftLayout';
import { UserContext } from '../utils/UserContext';
import './GiftSpace.scss';


function GiftSpace(props: any) {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const urlParams = new URLSearchParams(props.location.search);
  const sessionId: any = urlParams.get('session_id');
  const { isUserAuthenticated } = useContext(UserContext);

  useEffect(() => {
    if (sessionId) {
      setCurrentTab(1);
    }
  }, [sessionId])

  return (
    <div className="hp-giftspace">
      <EidGiftLayout closeScreen={() => history.push('/member')}>
        <div className='hp-giftspace-tabs'>
          {(sessionId || isUserAuthenticated) && (<>
            <div className={`hp-giftspace-tab-item ks-clickable ${currentTab === 0 ? 'selected' : ''}`} onClick={() => setCurrentTab(0)}>
              OFFRIR UNE CARTE CADEAU
          </div>
            <div className={`hp-giftspace-tab-item ks-clickable ${currentTab === 1 ? 'selected' : ''}`} onClick={() => setCurrentTab(1)}>
              ACCÉDER À MES CARTES
          </div>
          </>)}
        </div>
        {currentTab === 0 ? (
          <EidGift />
        ) : (
          <EidGiftCoupon sessionId={sessionId} />
        )}
      </EidGiftLayout>

    </div>
  );
}

export default GiftSpace;
