import './ChangePayment.scss'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import Stripe, { PaymentMethodResult, StripeCardNumberElementOptions } from "@stripe/stripe-js";
import { ClipLoader } from 'react-spinners';
import { ReactComponent as EmailIcon } from '../../../assets/v3/email-icon.svg';
import { ReactComponent as CBIcon } from '../../../assets/v3/cb-icon.svg';
import { ReactComponent as CVCIcon } from '../../../assets/v3/cvc-icon.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/v3/calendar-icon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/v3/warning-icon.svg';
import { ReactComponent as StripeLogo } from '../../../assets/v3/stripe-logo.svg';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import useResponsiveSize from '../../utils/useResponsiveSize';
import { UserContext } from '../../utils/UserContext';
import BaseDS from '../../../datastore/BaseDS';

const useOptions = () => {
  const fontSize = useResponsiveSize(20);
  const options: StripeCardNumberElementOptions = useMemo<StripeCardNumberElementOptions>(() => ({
    style: {
      base: {
        fontSize: fontSize + 'px',
        color: "black",
        fontFamily: "Avenir",
        fontWeight: "400",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#BF594C"
      }
    }
  }), [fontSize]);

  return options;
};

const ChangePayment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const elementsRef: any = useRef({});
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const options: StripeCardNumberElementOptions = useOptions();
  const { userInfo } = useContext(UserContext);

  const loaderSize = useResponsiveSize(30);

  useEffect(() => {
    setEmail(userInfo?.attributes && userInfo.attributes.email);
  }, [userInfo])

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    displayError('');
    setLoading(true);

    if (!stripe || !elements || !elements.getElement(CardNumberElement)) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const cardNumberElementObject: any = elements.getElement(CardNumberElement);

    const paymentMethod: PaymentMethodResult = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElementObject
    });
    if (paymentMethod.paymentMethod) {
      try {
        const setupIntent = await BaseDS.updateUserPaymentInfos(paymentMethod.paymentMethod?.id);
        await handleSetupThatRequiresCustomerAction(setupIntent, paymentMethod.paymentMethod?.id);
      } catch (error: any) {
        displayError({ error: { message: "Erreur durant la transaction." } });
        setLoading(false);
      }
    } else {
      displayError(paymentMethod);
      setLoading(false);
    }

  };

  const handleSetupThatRequiresCustomerAction = async (setupIntent: Stripe.SetupIntent, paymentMethodId: string) => {
    if (
      setupIntent.status === 'requires_action' ||
      setupIntent.status === 'requires_payment_method'
    ) {
      return stripe?.confirmCardSetup(setupIntent.client_secret || 'no_client_secret', {
        payment_method: paymentMethodId,
      })
        .then(async (result) => {
          console.log(result);
          if (result.error) {
            alert(result.error)
            throw result;
          } else {
            if (result.setupIntent.status === 'succeeded') {
              document.location.href = "/embedded/vw/member";
            } else {
              displayError(result.error);
            }
          }
        })
        .catch((error) => {
          displayError(error);
        }).finally(() => setLoading(false));
    } else {
      document.location.href = "/embedded/vw/member";
    }
  }

  const hasError = (cardElement: any) => {
    if (cardElement.error) {
      elementsRef.current[cardElement.elementType].classList.add("invalid");
    } else {
      elementsRef.current[cardElement.elementType].classList.remove("invalid");
    }
  }

  function displayError(event: any) {
    if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage('');
    }
  }

  return (<div className='hp-checkout'>
    <div className="hp-checkoutWrapper">
      <div className={"card-element-errors " + (!errorMessage ? "empty" : "")} role="alert">{errorMessage}</div>
      <form onSubmit={handleSubmit} className='hp-checkoutForm'>
        <label>
          <span>E-mail</span>
          <div className='field'>
            <EmailIcon className="fieldIcon email" />
            <input type='email' disabled placeholder="Tapez ici votre email" className="inputForm" value={email || ''} />
          </div>
        </label>
        <label>
          <span>Numéro de carte</span>
          <div className='field' ref={ref => elementsRef.current["cardNumber"] = ref}>
            <CBIcon className="fieldIcon cb" />
            <CardNumberElement options={options} className="inputForm" onChange={hasError} />
            <WarningIcon className="warning-icon" />
          </div>
        </label>
        <div className='grouped-field'>
          <label className='left'>
            <span>Date d’expiration</span>
            <div className='field' ref={ref => elementsRef.current["cardExpiry"] = ref}>
              <CalendarIcon className="fieldIcon calendar" />
              <CardExpiryElement options={options} className="inputForm" onChange={hasError} />
              <WarningIcon className="warning-icon" />
            </div>
          </label>
          <label className='right'>
            <span>Numéro de sécurité</span>
            <div className='field' ref={ref => elementsRef.current["cardCvc"] = ref}>
              <CVCIcon className="fieldIcon cvc" />
              <CardCvcElement options={options} className="inputForm" onChange={hasError} />
              <WarningIcon className="warning-icon" />
            </div>
          </label>
        </div>
        <button type="submit" disabled={!stripe} className={`${loading && 'disabled'}`}>
          {loading ? (<ClipLoader size={loaderSize} color="white" />) : (<>CHANGER</>)}
        </button>
        <div className='stripe-logo'>Carte sécurisée par&nbsp;<StripeLogo /></div>
      </form>
    </div>
  </div>);
};

export default ChangePayment;
