const appIdToLocalPort: any = {
  "muslimshow": 3021,
  "guides": 3031,
  "foulane": 3051,
  "foulanegames": 3000,
  "hoopow": 3001,
  "awlad": 3011,
  "quiz": 3041
}

export const contractWithBanner: any = {
  'no_plan': "PAS DE PLAN",
  'free_plan': "D’ESSAI GRATUIT",
  'month_gift_plan': "D’UTILISATION",
  'half_year_gift_plan': "D’UTILISATION",
  'year_gift_plan': "D’UTILISATION",
};

export const allowedToEmbbedFrame = (appId: string) => {
  if (appId === "hoopow" && process.env.REACT_APP_DOMAIN_NAME !== 'localhost') {
    return 'https://' + (process.env.REACT_APP_DOMAIN_NAME === "hoo-pow.com" ? "www.hoo-pow.com" : process.env.REACT_APP_DOMAIN_NAME)
  } else {
    const protocol = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? 'https' : 'http';
    const port = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? '' : (':' + appIdToLocalPort[appId] || "");
    const subDomain = process.env.REACT_APP_DOMAIN_NAME !== 'localhost' ? appId + '.' : '';
    return protocol + '://' + subDomain + process.env.REACT_APP_DOMAIN_NAME + port
  }
}

export const isEmbedded = () => {
  return document.location.pathname.indexOf("embedded") > -1;
}

export const getDayBeforeExpiration = (endTimestamp: number, userPlan: any) => {
  if (userPlan) {
    const endDate = new Date();
    endDate.setTime((endTimestamp * 1000));
    const today = new Date(new Date().toDateString());
    const diffInTime = endDate.getTime() - today.getTime();
    const nbDaysBeforeEnd = Math.floor(diffInTime / (1000 * 3600 * 24));
    return nbDaysBeforeEnd > 0 ? nbDaysBeforeEnd : 0;
  } else {
    return 0;
  }
}

export const getFormattedEndDate = (endTimestamp: number, userPlan: any): string => {
  if (userPlan) {
    const endDate = new Date();
    endDate.setTime((endTimestamp * 1000));
    return endDate.toLocaleString("fr-FR", { day: '2-digit', month: 'long', year: 'numeric' });
  } else {
    return '';
  }
}

export const trim = (value: string | undefined) => {
  return value ? value.trim() : value;
}