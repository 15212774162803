import {useEffect, useState} from "react";
import AvatarSelector from "./AvatarSelector";
import PasswordField from "../auth/PasswordField";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileDS from "datastore/FileDS";
import {ReactComponent as CheckedIcon} from '../../assets/v3/checked-icon.svg'
import {ReactComponent as WarningIcon} from '../../assets/v3/warning-icon.svg'
import {ReactComponent as UncheckedIcon} from '../../assets/v3/cancel-icon.svg'

enum state {
    VALID, INVALID, UNKOWN
}

type TField = "name" | "email" | "password" | "oldPassword" | "avatarId";

const UserForm = (props: any) => {
    const {onMobile} = props;
    const [loading, setLoading] = useState(false);
    const [selectingIcon, setSelectingIcon] = useState<boolean>(false);
    const [avatarSrc, setAvatarSrc] = useState(FileDS.getUrl("images/man_avatar-sample.svg"));
    const [formData, setFormData] = useState({
        email: props.userData?.email || '',
        name: props.userData?.name || '',
        oldPassword: '',
        avatarId: props.userData?.avatarId || 'images/man_avatar-sample.svg',
        password: ''
    });
    const [formDataState, setFormDataState] = useState({
        email: state.UNKOWN,
        name: state.UNKOWN,
        oldPassword: state.UNKOWN,
        avatarId: state.UNKOWN,
        password: state.UNKOWN
    });

    const handleInputChange = (e: any, prop: string) => {
        setFormData({
            ...formData, [prop]: e.target.value
        });
        setErrors((prev: any) => ({...prev, [prop]: ""}));
    };
    const [password, setPassword] = useState("")
    const [passwordIsConfirmed, setPasswordIsConfirmed] = useState<boolean | null>(null);
    const [errors, setErrors] = useState<any>({})
    useEffect(() => {
        console.log("passwordIsConfirmed", passwordIsConfirmed, password);
    }, [passwordIsConfirmed])

    const avatarSelected = (newAvatarId: string) => {
        console.log(newAvatarId);
        setFormData({
            ...formData, avatarId: newAvatarId
        });
    }

    useEffect(() => {
        // Configuring the notification
        toast.configure();
    }, []);
    useEffect(() => {
        setFormDataState(prev => ({
            ...prev, email: getFieldValidity("email", formData.email)
        }))
    }, [formData.email]);
    useEffect(() => {
        setFormDataState(prev => ({
            ...prev, name: getFieldValidity("name", formData.name)
        }))
    }, [formData.name]);
    useEffect(() => {
        setFormDataState(prev => ({
            ...prev, oldPassword: getFieldValidity("oldPassword", formData.oldPassword)
        }))
    }, [formData.oldPassword]);
    useEffect(() => {
        setFormDataState(prev => ({
            ...prev, avatarId: getFieldValidity("avatarId", formData.avatarId)
        }))
    }, [formData.avatarId]);
    useEffect(() => {
        setFormDataState(prev => ({
            ...prev, password: getFieldValidity("password", formData.password)
        }))
    }, [formData.password]);
    const getFieldValidity = (field: TField, value: string) => {
        switch (field) {
            //TODO:replace "false" with the appropriate condition for INVALID
            case "name":
                return value.length <= 0 ? state.UNKOWN : false ? state.INVALID : state.VALID;
            case "email":
                return value.length <= 0 ? state.UNKOWN : false ? state.INVALID : state.VALID;
            case "password":
                return value.length <= 0 ? state.UNKOWN : false ? state.INVALID : state.VALID;
            case "oldPassword":
                return value.length <= 0 ? state.UNKOWN : false ? state.INVALID : state.VALID;
            case "avatarId":
                return value.length <= 0 ? state.UNKOWN : false ? state.INVALID : state.VALID;

            default:
                return state.UNKOWN;
        }
    };

    const stateToClassName = (s: state) => (" " + (s === state.VALID ? "valid" : s === state.INVALID ? "invalid" : "unknown") + " ");

    return (<>
        <div className="ks-form-left">
            <form className='ks-form'>
                <div className={"ks-form-group" + stateToClassName(formDataState.name)}>
                    <CheckedIcon className="checked"/>
                    <UncheckedIcon className="unchecked"/><WarningIcon className="warning"/>
                    <label className="form-label">Nom d'utilisateur :</label>
                    <input type='text' autoComplete="off" placeholder="Tapez ici votre nom"
                           value={formData.name || ''} onChange={(e: any) => handleInputChange(e, 'name')}
                           className="ks-form-control"
                    />
                    <div className="ks-form-error">{errors.name}</div>
                </div>
                <div className={"ks-form-group" + stateToClassName(formDataState.email)}>

                    <CheckedIcon className="checked"/>
                    <UncheckedIcon className="unchecked"/><WarningIcon className="warning"/>
                    <label className="ks-form-label">E-mail :</label>
                    <input type='email' name='email' autoComplete="email" disabled={props.changePasswordField}
                           placeholder="Tapez ici votre email" value={formData.email || ''}
                           onChange={(e: any) => handleInputChange(e, 'email')}
                           className="ks-form-control"
                    />
                    <div className="ks-form-error">{errors.email}</div>
                </div>
                <div className={"ks-form-group" + stateToClassName(formDataState.password)}>

                    <CheckedIcon className="checked"/>
                    <UncheckedIcon className="unchecked"/><WarningIcon className="warning"/> <label
                    className="ks-form-label">{props.changePasswordField ? "Nouveau mot de passe :" : "Mot de passe :"}</label>
                    <PasswordField placeholder="Tapez ici votre mot de passe" autoComplete="new-password"
                                   onChange={(e: any) => {
                                       setPassword(e.target.value);
                                       handleInputChange(e, 'password')
                                   }}
                                   className="ks-form-control"/>
                    <div
                        className="ks-form-error ks-form-error__small ks-form-error__overflow">{errors.password}</div>

                </div>
                <div
                    className={"ks-form-group " + (passwordIsConfirmed ? "valid" : false === passwordIsConfirmed ? "invalid" : "unknown")}>
                    <CheckedIcon className="checked"/>
                    <UncheckedIcon className="unchecked"/><WarningIcon className="warning"/>
                    <label
                        className="ks-form-label">Confirmer le mot de passe<span
                        className="desktop-only">&nbsp;:</span></label>
                    <PasswordField placeholder="Confirmer votre mot de passe" autoComplete="new-password"
                                   onChange={(e: any) => setPasswordIsConfirmed(password === e.target.value)}
                                   className="ks-form-control"/>
                    <div
                        className="ks-form-error ks-form-error__small">{false === passwordIsConfirmed ? "Les mots de passe ne correspondent pas." : ""}</div>
                </div>
                {props.changePasswordField && (
                    <div className={"ks-form-group" + stateToClassName(formDataState.oldPassword)}>

                        <CheckedIcon className="checked"/>
                        <UncheckedIcon className="unchecked"/><WarningIcon className="warning"/> <label
                        className="ks-form-label">Ancien mot de passe
                        :</label>
                        <PasswordField placeholder="Tapez ici votre ancien mot de passe" autoComplete="password"
                                       onChange={(e: any) => handleInputChange(e, 'oldPassword')}
                                       className="ks-form-control"/>
                        <div className="ks-form-error">{errors.oldPassword}</div>
                    </div>)}
            </form>
            {onMobile ? <>
                <div className={"ks-separator"}></div>
                <button className="ks-avatar-selector-btn" onClick={() => {
                    setSelectingIcon(true)
                }}>
                    <img src={avatarSrc} alt=""/>
                    <span>CHOISIR UNE IMAGE DE PROFIL</span>
                </button>
            </> : <></>}
            <div className="ks-formBtn">
                <button className="mainBtn" onClick={() => {
                    setLoading(true);
                    props.submit(formData, (error: string) => {
                        setLoading(false);
                        if (error) {
                            if (error.indexOf("e-mail") > -1) {
                                setErrors((prev: any) => ({...prev, email: error}))
                                setFormDataState(prev => ({...prev, email: state.INVALID}))
                            } else if (error.indexOf("mot de passe") > -1) {
                                setErrors((prev: any) => ({...prev, password: error.substring(0, 56).concat(".")}))
                                setFormDataState(prev => ({...prev, password: state.INVALID}))
                            } else {
                                toast.error(error, {position: toast.POSITION.TOP_CENTER});
                            }
                        } else {
                            toast.success(`Vos données ont été enregistrées correctement.`, {position: toast.POSITION.TOP_CENTER});
                        }
                    })
                }} type="submit"
                        disabled={(!props.changePasswordField && !passwordIsConfirmed) || !props.isValidForm(formData) || loading}>
                    VALIDER
                </button>
            </div>
        </div>
        {props.showAvatar ? (!onMobile ? (<div className="ks-form-right">
            <AvatarSelector onMobile={false} setAvatarId={avatarSelected}/>
        </div>) : selectingIcon ? <AvatarSelector onMobile={onMobile}
                                                  setSelectingIcon={setSelectingIcon}
                                                  setAvatarId={avatarSelected}
                                                  setAvatarSrc={setAvatarSrc}
                                                  className={"mobile-only"}/> : <></>) : <></>}
    </>);
}
export default UserForm;