import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import FileDS from '../../datastore/FileDS';

const ImageComponentWithLoader = (props: {
  children: any;
  imageKey: any;
  onClick?: any;
  alt: string | undefined;
  width: string | number | undefined;
  className: string | undefined;
}) => {
  const [fileURL, setFileURL] = useState('');
  const [loaded, setLoaded] = useState<boolean>(false);
  const { children } = props;

  useEffect(() => {
    if (props.imageKey) {
      FileDS.getFileUrl(props.imageKey, (url: any, err: any) => {
        setFileURL(url);
        setLoaded(true);
      });
    } else {
      setFileURL('');
    }
  }, [props.imageKey]);

  return (
    <>
      {loaded ? (<>
        { fileURL ? (<>
          <img
            src={fileURL}
            alt={props.alt}
            width={props.width}
            onClick={props.onClick}
            className={props.className}
          />

        </>) : (<>{children}</>)}
      </>) : (<div className='hp-clipLoader'><ClipLoader /></div>)
      }
    </>
  );
};

export default function Image(props: any) {
  return <ImageComponentWithLoader {...props} />;
}
