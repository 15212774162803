import { MouseEventHandler, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import FileDS from '../../datastore/FileDS';

type SVGProps = {
  src: string | null | undefined,
  afterInjection?: any,
  className?: string,
  style?: any,
  onClick?: MouseEventHandler<any>
}

const SVG = (props: SVGProps) => {
  const [svgUrl, setSVGUrl] = useState<string>();
  const { src, afterInjection } = props;
  useEffect(() => {
    if (src) {
      FileDS.getFileUrl(src, (url: any, err: any) => {
        setSVGUrl(url);
      });
    }
  }, [src]);
  return (
    <div onClick={props.onClick} className={props.className ? props.className : ''} style={props.style} >
      <ReactSVG src={svgUrl ? svgUrl : ''} afterInjection={afterInjection} />
    </div>
  );
};

export default SVG;
