import { useHistory } from 'react-router';
import EidGift from '../modal/EidGift';
import EidGiftLayout from '../modal/EidGiftLayout';
import './GiftSpace.scss';


function GiftSpace(props: any) {
  const history = useHistory();

  return (
    <EidGiftLayout closeScreen={() => history.push('/')}>
      <EidGift />
    </EidGiftLayout>
  );
}

export default GiftSpace;
