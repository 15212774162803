import './css/AppSwitcher.scss';

import { ReactComponent as HoopowLogo } from '../assets/hoopow-logo-tm.svg';
import { ReactComponent as AwladSchoolIcon } from '../assets/awlad-logo.svg';
import { ReactComponent as FoulaneIcon } from '../assets/foulane-icon.svg';
import { ReactComponent as BDouinIcon } from '../assets/bdouin-logo.svg';
import { Link } from 'react-router-dom';
import Cookies from './elements/common/Cookies';

function AppSwitcher() {
  return (<div className='hp-appswitcher'>
    <div className='hp-appswitcher-wrapper'>
      <Link to="/home"><HoopowLogo className='hp-hoopowLogo' /></Link>
      <div className='hp-links'>
        <a href="http://awlad-school.com/">
          <AwladSchoolIcon />
          <h2>AWLAD SCHOOL</h2>
          <p>Apprenez à lire et écire<br />l’arabe de façon autonome !<br />100% gratuit.</p>
          <label>ACCÉDER</label>
        </a>
        <button>
          <Link to="/foulane/">
          <FoulaneIcon />
          <h2>FAMILLE FOULANE</h2>
          <p>La célèbre série des<br />Editions du BDouin<br />avec Walad et Binti !</p>
          {/*<label className='soon'>PROCHAINEMENT</label>*/}
            <label>ACCÉDER</label>
          </Link>
        </button>
      </div>
    </div>
    <div className='hp-old-version'>
      <Link to="/home">Accéder à l’ancienne version de la plateforme <span>HOOPOW</span></Link>
    </div>
    <Cookies />
    <div className='hp-mention'>
      <BDouinIcon />
      <p>HOOPOW est une plateforme ludo-éducative dirigée par le Studio <a href="https://www.bdouin.com">BDouin</a></p>
    </div>
  </div>);
}

export default AppSwitcher;
