
import HomepageUnauthentified from './HomepageUnauthentified';
import HomepageV3 from './HomepageV3';
import HomepageNewDesignAuthenticated from './HomepageNewDesignAuthenticated';
import { ReactComponent as AdvertEidGift } from '../assets/advert-eidgift-aladha.svg';
import { ReactComponent as AdvertEidGiftMobile } from '../assets/advert-eidgift-aladha-mobile.svg';
import { ReactComponent as CloseBtn } from '../assets/close-btn.svg';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import LayoutV2 from './layout/LayoutV2';
import UserContextProvider from './utils/UserContext';

const enableAd: boolean = process.env.REACT_APP_ENABLE_AD === '1';

const adClosedCookieName = 'adClosedAidAlAdha2021'

function Homepage(props: any) {

  const history = useHistory();
  const [adClosed, setAdClosed] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const modalElement: HTMLElement = document.body;
  useEffect(() => {
    if (!adClosed) modalElement.classList.add('nooverflow');
    else modalElement.classList.remove('nooverflow');
  }, [adClosed, modalElement])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!localStorage.getItem(adClosedCookieName)) {
        setAdClosed(!enableAd);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [])

  const closeAd = () => {
    setAdClosed(true);
    localStorage.setItem(adClosedCookieName, 'true');
  }

  return (<>

    {!adClosed && (
      <Modal
        isOpen={!adClosed}
        onRequestClose={closeAd}
        portalClassName={'ReactModalPortal hp-advert'}
        contentLabel="Cadeau du Aïd"
        parentSelector={() => modalElement}
        appElement={modalElement}
      >
        <CloseBtn className='hp-closeBtn ks-clickable' onClick={closeAd} />
        <button className='mainBtn' onClick={() => {
          if (props.isAuthenticated) {
            localStorage.setItem(adClosedCookieName, 'true');
            history.push('/giftspace')
          } else {
            setOpenModal(true);
            closeAd();
          }
        }}>EN SAVOIR PLUS</button>
        <AdvertEidGiftMobile className='hp-advert-image-mobile' />
        <AdvertEidGift className='hp-advert-image' />
      </Modal>
    )}
    {props.isAuthenticated ? (<>
      <UserContextProvider location={props.location}>
        <LayoutV2 isAuthenticated={props.isAuthenticated} {...props}>
          {process.env.REACT_APP_FEATURE_NEW_DESIGN_V3 && parseInt(process.env.REACT_APP_FEATURE_NEW_DESIGN_V3)
            ? <HomepageV3 {...props} />
            : <HomepageNewDesignAuthenticated {...props} />}
        </LayoutV2>
      </UserContextProvider>
    </>) : (<>
      <HomepageUnauthentified {...props} openModal={openModal} />
    </>)
    }
  </>);
}

export default Homepage;
