import './EmptyOrder.scss';

function EmptyOrder() {

  return (
    <div className="hp-myorders-empty">
      <div className="hp-myorders-empty-rect"></div>
      <div className="hp-myorders-empty-ordertype"></div>
      <div className="hp-myorders-empty-orderdate"></div>
    </div>
  );
}

export default EmptyOrder;
