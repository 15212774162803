import { useEffect, useState } from "react";

export default function useResponsiveSize(initialOptimalSize: number) {
  const getSize = (optimalSize: number) => {
    const mainContentHeight = document.documentElement.style.getPropertyValue('--mainContentHeight').replace("px", "");
    return optimalSize * parseInt(mainContentHeight) / 1038;
  };
  const [size, setSize] = useState(getSize(initialOptimalSize));

  useEffect(() => {
    const onResize = () => {
      setSize(getSize(initialOptimalSize));
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return size;
}
