import { useContext, useEffect, useState } from 'react';
import './Member.scss';
import Modal from 'react-modal';
import { ReactComponent as IconCB } from './assets/icon-credit-card.svg';
import { ReactComponent as IconMemberSpace } from './assets/icon-memberspace.svg';
import { ReactComponent as IconInfo } from '../../assets/pause/icon-info.svg';
import UserDS from '../../datastore/UserDS';
import { TUserPaymentInfo, TUserPlan } from '../../types';
import { ClipLoader } from 'react-spinners';
import { useGA4React } from 'ga-4-react';
import { UserContext } from '../utils/UserContext';
import { allowedToEmbbedFrame, getDayBeforeExpiration, getFormattedEndDate } from '../../utils/Constants';
import EditUser from './EditUser';
import BaseDS from '../../datastore/BaseDS';

const planLabel: any = {
  no_plan: "Aucun",
  monthly_plan: 'Abonnement mensuel',
  yearly_plan: 'Abonnement annuel',
  yearly_plus_plan: 'Abonnement++ annuel'
}

const contractNoRenewal: any = {
  'free_plan': "d’essai gratuit",
  'month_gift_plan': "d'utilisation",
  'quarter_year_gift_plan': "d'utilisation",
  'half_year_gift_plan': "d'utilisation",
  'year_gift_plan': "d'utilisation",
};

function Member(props: any) {
  const ga: any = useGA4React();
  const urlParams = new URLSearchParams(props.location?.search);

  const [loadedPlan, setLoadedPlan] = useState<boolean>(false);
  const [userPlan, setUserPlan] = useState<TUserPlan>();
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentInfoLoading, setPaymentInfoLoading] = useState<boolean>(true);
  const [userPaymentInfo, setUserPaymentInfo] = useState<TUserPaymentInfo>();
  const [confirmationCancellationIsOpen, setConfirmationCancellationIsOpen] = useState<boolean>(false);
  const sessionId: any = urlParams.get('session_id');
  const appId: any = urlParams.get('appId');
  const modalElement: any = document.getElementById('embedded-frame');

  const { isUserAuthenticated } = useContext(UserContext);

  useEffect(() => {
    if (sessionId && !loadedPlan) {
      setLoadedPlan(true);
      UserDS.getUserPlan(sessionId).then((plan: TUserPlan) => {
        setUserPlan(plan);
      })
    } else if (isUserAuthenticated && !loadedPlan) {
      UserDS.getUserPlan().then((plan: TUserPlan) => {
        setUserPlan(plan);
      })
    }
  }, [sessionId, isUserAuthenticated, loadedPlan]);

  const cancelPlan = () => {
    setLoading(true);
    UserDS.cancelPlan().then((plan: TUserPlan) => {
      setUserPlan(plan);
      setConfirmationCancellationIsOpen(false);
      setLoading(false);
    })
  }

  useEffect(() => {
    BaseDS.getUserPaymentInfo().then((data) => {
      setUserPaymentInfo(data);
    }).finally(() => setPaymentInfoLoading(false));
  }, [])

  const dayBeforeExpiration = userPlan ? getDayBeforeExpiration(userPlan.periodEnd, userPlan) : -1;

  return (
    <div className="hp-embedded-member">
      {modalElement && confirmationCancellationIsOpen && (
        <Modal
          isOpen={confirmationCancellationIsOpen}
          onRequestClose={() => setConfirmationCancellationIsOpen(false)}
          portalClassName={'ReactModalPortal'}
          contentLabel="Member Plans"
          parentSelector={() => modalElement}
          appElement={modalElement}
        >
          <div className='hp-embedded-member-cancel-confirmation ks-boxShadow'>
            <div>Êtes-vous sûr de vouloir annuler votre abonnement ?</div>
            <div className='hp-embedded-member-cancel-btn'>
              <button className='mainBtn hp-embedded-plan-cancel-okBtn' disabled={loading} onClick={() => cancelPlan()} >OUI</button>
              <button className='mainBtn hp-embedded-plan-cancel-noBtn' disabled={loading} onClick={() => setConfirmationCancellationIsOpen(false)} >NON</button>
            </div>
          </div>
        </Modal>
      )}
      <div className="hp-embedded-member-header"><IconMemberSpace />espace membre</div>
      <div className="hp-embedded-member-boxes">
        <div className='hp-embedded-memberDetails'>
          <EditUser showAvatar={false} {...props} />
        </div>
        <div className='hp-embedded-memberBilling'>
          <div className={'hp-embedded-member-contract ks-boxShadow' + ((userPlan && (contractNoRenewal[userPlan.contractType] || userPlan.status !== 'active')) || (process.env.REACT_APP_FEATURE_PAUSE === '1') ? ' hp-embedded-free-period' : '')}>
            {userPlan ? (<>
              {(userPlan.status === 'active') ? (<>
                {process.env.REACT_APP_FEATURE_PAUSE === '1' ? (<>
                  <div className='hp-embedded-member-info-title'><IconInfo />INFORMATIONS</div>
                  <div className='hp-embedded-member-info-desc'>Votre abonnement a été stoppé<br />par la plateforme.</div>
                  <div className='hp-embedded-member-info-subdesc'>Le remboursement de votre dernier<br />mois de cotisation a été lancé sur<br />votre compte bancaire.</div>
                </>) : (
                  <>
                    {contractNoRenewal[userPlan.contractType] ? (<>
                      <span>Il vous reste {dayBeforeExpiration} {dayBeforeExpiration < 2 ? 'jour' : 'jours'} {contractNoRenewal[userPlan.contractType]}</span>
                      <button className='mainBtn testChanges' disabled={loading} onClick={() => {
                        setLoading(true);
                        document.location.href = "/embedded/vw/checkout?appId=platform&productId=monthly_plan_v3&itemId=full"
                      }} >S’ABONNER</button>
                    </>) : (<>
                      <span className='hp-embedded-member-contract-title'>VOTRE TYPE D’ABONNEMENT</span>
                      <span className='hp-embedded-member-contract-type'>{planLabel[userPlan.contractType]}</span>
                      {userPlan.contractType !== 'no_plan' && (
                        <>
                          {userPlan.cancelAtPeriodEnd ? (<>
                            <span className='hp-embedded-member-contract-desc'>Votre abonnement s'arrêtera le <label>{getFormattedEndDate(userPlan.periodEnd, userPlan)}</label></span>
                          </>) : (<>
                            <span className='hp-embedded-member-contract-desc'>Votre prochain paiement de <label>{userPlan.price / 100}€</label> aura lieu le <label>{getFormattedEndDate(userPlan.periodEnd, userPlan)}</label></span>
                            <button className='mainBtn' disabled={loading} onClick={() => {
                              if (ga) ga.event('platform', "Cancellation", 'engagement');
                              setConfirmationCancellationIsOpen(true)
                            }} >ANNULER L’ABONNEMENT</button>
                          </>)}
                        </>)}
                    </>)
                    }
                  </>
                )}
              </>) : (<>
                {process.env.REACT_APP_FEATURE_PAUSE === '1' ? (<>
                  <div className='hp-embedded-member-info-title'><IconInfo />INFORMATIONS</div>
                  <div className='hp-embedded-member-info-desc'>Impossible de s’abonner<br />pour le moment.</div>
                </>) : (
                  <>
                    <span>{userPlan.contractType === 'no_plan' ? 'Aucun abonnement.' : 'Votre abonnement a expiré.'}</span>
                    <button className='mainBtn' onClick={() => {
                      if (ga) ga.event('platform', "Subscribe after expiration", 'engagement');
                      if (window.parent) {
                        window.parent.postMessage({ 'step': 'subscription' }, allowedToEmbbedFrame(appId));
                      }
                    }} >S’ABONNER</button>
                  </>
                )}
              </>)}
            </>) : (<div className='hp-embedded-planLoader'><ClipLoader /></div>)}
          </div>
          <div className='hp-embedded-member-cb ks-boxShadow'>
            <span className='hp-embedded-member-cb-title'>MÉTHODE DE PAIEMENT</span>
            {!paymentInfoLoading ? (<>
              <span className='hp-embedded-member-cb-number'><IconCB />**** **** **** {userPaymentInfo?.last4}</span>
              <button className='mainBtn' disabled={loading || !userPaymentInfo} onClick={async () => {
                setLoading(true);
                document.location.href = "/embedded/vw/changePayment"
              }}>MODIFIER</button>
            </>) : (<><ClipLoader /></>)}
          </div>
        </div>
      </div >
    </div >
  );
}

export default Member;
