
import DOMPurify from 'dompurify';
import { useEffect, useRef } from 'react';

function InnerHtml(props: {className: string, text: string}) {
  const svgRef = useRef<any>();
  useEffect(() => {
    svgRef.current.innerHTML = DOMPurify.sanitize(props.text);
  }, [props.text])

  return (
    <div ref={svgRef} className={props.className}/>
  );
}

export default InnerHtml;
