import { TExplanationInfoItem } from '../utils/Constants';
import './ExplanationTemplate.scss'

const ExplanationTemplate = (props: { info: TExplanationInfoItem }) => {
  const { info } = props;

  return (<div className='hp-explanation-template'>
    <div className='hp-explanation-template-title'>
      {info.titleImage}{info.title}
    </div>
    {info.text && (
      <div className='hp-explanation-template-text'>{info.text}</div>
    )}
    <div className='hp-explanation-template-bottomImage'>{info.bottomImage}</div>

  </div>);
};

export default ExplanationTemplate;
