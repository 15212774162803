import { useContext, useEffect } from 'react';
import './css/HomepageV3.scss';
import { TApplication, TAppNews } from '../types';
import { ReactComponent as OpenBtn } from '../assets/v3/open-btn.svg';
import { ReactComponent as SoonBtn } from '../assets/v3/soon-btn.svg';
import { ReactComponent as Banner } from '../assets/pause/banner.svg';
import ImageComponentWithLoader from './elements/ImageComponentWithLoader';
import { ClipLoader } from 'react-spinners';
import { UserContext } from './utils/UserContext';
import SVG from './elements/SVG';
import { MainConfigContext } from './utils/MainConfigContext';
import { BlockerContext, steps } from './utils/BlockerContext';

function HomepageV3() {
  const { mainConfig, advertsConfig, appById, loading } = useContext(MainConfigContext);
  const { isUserAuthenticated } = useContext(UserContext);
  const { setBlock, setUnblockStep } = useContext(BlockerContext);

  useEffect(() => {
    document.title = 'HOO POW';
  }, []);

  const publishedApp = (applicationDef: TApplication) => {
    return applicationDef
      && applicationDef.publicationDate
      && parseInt(applicationDef.publicationDate.replace(/-/g, '')) <= parseInt(new Date().toISOString().replace(/-/g, ''))
  }

  return (
    <div className='hp-mainContent hp-homepageV3'>
      <div className='hp-appNews'>
        <div className='hp-appNews-frame' >
          {process.env.REACT_APP_FEATURE_PAUSE === '1' ? (
            <Banner className='hp-appNews-banner' />
          ) : (<>
            {loading ? (<><ClipLoader color='white' /></>) : (<>
              {mainConfig && mainConfig.appNews && mainConfig.appNews.slice(0, 3).map((appNewTmp: TAppNews, index: number) => (
                <a id={'news_' + index} key={'news_' + index} className={'hp-appNews-line '} href={appNewTmp.urlToResource}
                  onClick={(event) => {
                    if (appNewTmp.urlToResource.indexOf('awlad.') > -1 && !isUserAuthenticated) {
                      event.preventDefault();
                      setUnblockStep(steps.AWLAD_SCHOOL);
                      setBlock(true);
                      return false;
                    }
                  }}>
                  <>
                    <ImageComponentWithLoader imageKey={appNewTmp.newsImage} />
                    <div className='hp-appNews-line-frame'>
                      <div>
                        <div className='hp-appNews-line-appName'>
                          {appById[appNewTmp.applicationId].name}
                        </div>
                        <div className='hp-appNews-line-title'>{appNewTmp.name}</div>
                        <div className='hp-appNews-line-description'>{appNewTmp.description}</div>
                      </div>
                    </div>
                  </>
                </a>
              ))}
            </>)}
          </>)}
        </div>
      </div>
      <div className='hp-appCards'>
        <div className='hp-appCards-title' >NOS APPLICATIONS</div>
        <div className='hp-appCards-frame'>
          {loading ? (<><ClipLoader color='white' /></>) : (<>
            {mainConfig && mainConfig.application && mainConfig.application.slice(0, 8).map((app: any, index: number) => (
              <a id={'news_' + index} key={'news_' + index} className={'hp-appCards-line ' + (!publishedApp(app) && 'ks-disabled')} href={app.url}
                onClick={(event) => {
                  if (app.url.indexOf('awlad.') > -1 && !isUserAuthenticated) {
                    event.preventDefault();
                    setUnblockStep(steps.AWLAD_SCHOOL);
                    setBlock(true);
                    return false;
                  }
                }}>
                <SVG src={app.imageHomepage} className='hp-appCards-line-app-logo' />
                <div className='hp-appCards-line-frame'>
                  <div className='hp-appCards-line-title'>
                    {app.title}
                  </div>
                  <div className='hp-appCards-line-description'>{app.description}</div>
                  {publishedApp(app) ? (
                    <OpenBtn className='hp-appCards-line-btn' />
                  ) : (
                    <SoonBtn className='hp-appCards-line-btn' />
                  )}
                </div>
              </a>
            ))}
          </>)}
        </div>
      </div>
      <div className='hp-appSoon'>
        <div className='hp-appSoon-title' >L’ACTUALITÉ DU MOMENT</div>
        <div className='hp-appSoon-frame'>
          {loading ? (<><ClipLoader color='white' /></>) : (<>
            {advertsConfig?.application.map((advert: any, index: number) => (
              <a href={advert.url} key={'soon_ad' + index} className={`hp-appSoon-ad-frame ks-clickable`}
                onClick={(event) => {
                  if (advert.url.indexOf('awlad.') > -1 && !isUserAuthenticated) {
                    event.preventDefault();
                    setUnblockStep(steps.AWLAD_SCHOOL);
                    setBlock(true);
                    return false;
                  }
                }}>
                <SVG src={advert.imageHomepage} className='hp-appSoon-ad-image' />
                <div className='hp-appSoon-ad-text'>
                  <div className='hp-appSoon-ad-text-left'>
                    <div className='hp-appSoon-ad-title'>{advert.name}</div>
                    <div className='hp-appSoon-ad-description'>{advert.title}</div>
                  </div>
                  <div className='hp-appSoon-ad-text-right'>{advert.description}</div>
                </div>
              </a>
            ))}
          </>)}
        </div>
      </div>
    </div>
  );
}

export default HomepageV3;
