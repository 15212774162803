import { useState } from "react";
import { Auth } from 'aws-amplify';

const SignUpForm = (props: any) => {
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(props.location.search);
  const sessionId: any = urlParams.get('session_id');

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
    repassword: '',
    codepromo: sessionId ? sessionId : ''
  });
  const handleInputChange = (e: any, prop: string) => {
    setFormData({
      ...formData,
      [prop]: e.target.value
    });
  };

  const submitSignUp = async () => {
    setLoading(true);
    try {
      const params: any = {
        username: formData.email.trim(),
        password: formData.password,
        attributes: {
          email: formData.email.trim(),
          name: formData.name.trim()
        }
      }
      if (process.env.REACT_APP_FEATURE_FREEMIUM === '1') {
        if (sessionId) params.attributes['custom:sessionId'] = sessionId;
        else params.attributes['custom:couponId'] = formData.codepromo;
      }
      await Auth.signUp(params);
      await Auth.signIn(formData.email.trim(), formData.password);
      setLoading(true);
    }
    catch (error: any) {
      console.log(error);
      if (error.code && error.code === 'UsernameExistsException') {
        alert('Cette adresse e-mail est déjà utilisée. N’hésitez pas à cliquer sur Mot de passe oublié en cas de besoin.');
      } else if (error.code && (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException')) {
        alert('Votre mot de passe doit contenir au minimum 8 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.');
      } else {
        alert("Unknown error => " + error.message);
      }
      console.log('error Loading');
      setLoading(false);
    }
  }

  const isValidForm = () => {
    return !(formData.name === '' ||
      formData.email === '' ||
      formData.password === '' ||
      formData.repassword === '' ||
      (formData.codepromo === '' && process.env.REACT_APP_FEATURE_FREEMIUM === '1') ||
      formData.password !== formData.repassword)
  }

  return (
    <div className="ks-login">
      <form className='ks-form'>
        <div className="ks-form-group">
          <label className="form-label">Nom d'utilisateur :</label>
          <input type='text' placeholder="Tapez ici votre nom" onChange={(e) => handleInputChange(e, 'name')}
            className="ks-form-control"
          />
        </div>
        <div className="ks-form-group">
          <label className="ks-form-label">E-mail :</label>
          <input type='email' placeholder="Tapez ici votre email" onChange={(e) => handleInputChange(e, 'email')}
            className="ks-form-control"
          />
        </div>
        <div className="ks-form-group">
          <label className="ks-form-label">Mot de passe :</label>
          <input type="password" placeholder="Tapez ici votre mot de passe" onChange={(e) => handleInputChange(e, 'password')} className="ks-form-control" />
        </div>
        <div className="ks-form-group">
          <label className="ks-form-label">Confirmation du mot de passe :</label>
          <input type="password" placeholder="Confirmez ici votre mot de passe" onChange={(e) => handleInputChange(e, 'repassword')} className="ks-form-control" />
        </div>
        {!sessionId && process.env.REACT_APP_FEATURE_FREEMIUM === '1' && (
          <div className="ks-form-group">
            <label className="ks-form-label">Code promo :</label>
            <input type="text" placeholder="Veuillez saisir votre code promo" onChange={(e) => handleInputChange(e, 'codepromo')} className="ks-form-control" />
          </div>
        )}
      </form>
      <div className="ks-formBtn">
        <button className="mainBtn" onClick={() => { submitSignUp() }} type="submit" disabled={!isValidForm() || loading}>
          CONTINUER
        </button>
      </div>
    </div>
  );
}
export default SignUpForm;