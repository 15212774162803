import "./Slider.scss"

const Slide = (props:{children:any, className?:any}) => {
  return (
    <div className={`"content-slide" ${props.className}`} >
      {props.children}
    </div>
  )
}

export default Slide
