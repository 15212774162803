import { ReactSVG } from 'react-svg';
import SVG from '../../elements/SVG';
import './OrderElement.scss';


const currencyCodeToSymbol: any = {
  "eur": "€"
}

const productPaymentType: any = {
  "one_time": "paiement",
  "recurring": "abonnement",
  "share": "partage"
}

function OrderElement(props: any) {
  const { orderDetails } = props;

  return (
    <div className="hp-myorders-item">
      <div className="hp-myorders-item-desc">
        {orderDetails.purchaseDetails?.image ? (
          <>
            {orderDetails.purchaseDetails?.image.indexOf(".svg") > -1 ? (
            <ReactSVG src={orderDetails.purchaseDetails?.image} />
            ) : (
              <img src={orderDetails.purchaseDetails?.image} alt={orderDetails.purchaseDetails?.title} />
            )}
          </>
        ) : (
          <SVG src={orderDetails.purchaseDetails?.imgKey} />
        )}
        <div>
          <div className='hp-myorders-item-desc-title'>{orderDetails.purchaseDetails?.title}</div>
          <div className='hp-myorders-item-desc-text'>{orderDetails.purchaseDetails?.description}</div>
          <div className='hp-myorders-item-desc-content'>
            <strong>Contenu : </strong>{orderDetails.purchaseDetails?.content}
          </div>
          {orderDetails.purchaseDetails?.status && (
            <div className='hp-myorders-item-desc-status'>
              <strong>État : </strong>{orderDetails.purchaseDetails?.status}
            </div>
          )}
        </div>
      </div>
      <div className="hp-myorders-item-ordertype">
        Type d’achat&nbsp;:&nbsp;
        {orderDetails.purchaseType !== "share" ? (<>
          {productPaymentType[orderDetails.productDetailsType]}&nbsp;
          {orderDetails.price / 100}{currencyCodeToSymbol[orderDetails.currency]}</>) : (<>
            {productPaymentType[orderDetails.purchaseType]}
          </>)}
      </div>
      <div className="hp-myorders-item-orderdate">
        Effectué le&nbsp;:&nbsp;{new Date(orderDetails.dateTimeTransaction).toLocaleDateString()}
      </div>
    </div>
  );
}

export default OrderElement;
